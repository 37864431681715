import { sendEventToDataLayer } from './dataLayerEvent'

export default {
  booking: {
    selectPaymentType: (type: string) => {
      sendEventToDataLayer('AVN_Booking', 'select_payment_type', type)
    },
    selectAmountPrepaid: (value: number | number[]) => {
      sendEventToDataLayer('AVN_Booking', 'interaction_prepayment', value)
    },
    agree: (model: string) => {
      sendEventToDataLayer('AVN_Booking', 'agreement', model)
    },
    submit: (paymentType: string, model: string) => {
      sendEventToDataLayer('AVN_Booking', paymentType, model)
    },
    success: (paymentType: string, model: string) => {
      sendEventToDataLayer('AVN_Booking', paymentType, model)
    },
    clickDealerPhone: (model: string) => {
      sendEventToDataLayer('AVN_Booking', 'click_dealer_phone', model)
    },
  },
}
