import { useEffect, useState } from 'react'

const useTimer = (paymentLink: string) => {
  const [time, setTime] = useState(10)

  useEffect(() => {
    if (paymentLink && time > 0) {
      const timer = setInterval(() => setTime(time - 1), 1000)

      return () => clearInterval(timer)
    }
  }, [time, paymentLink])

  return {
    time,
    setTime,
  }
}

export default useTimer
