import React, { Fragment } from 'react'
import { Text } from '@audi/audi-ui-react'

import { IDealerInfo } from '@app/types'
import convertTime from '@app/utils/convertTime'
import moneyFormat from '@app/utils/moneyFormat'

import {
  Description,
  StyledButton,
  StyledFail,
  StyledSuccess,
  Title,
} from '../styled'

enum Status {
  RESERVE_CANCELED = 'reserve_canceled',
  RESERVE_PAYED_FULL = 'reserve_payed_full',
  RESERVE_PAYED_NOT_FULL = 'reserve_payed_not_full',
  RESERVE_NOT_PAYED = 'reserve_not_payed',
  PAYMENT_WAIT = 'payment_wait',
  DEALER_PAYMENT_WAIT = 'dealer_payment_wait',
  RSE_RESERVE_WAIT = 'rse_reserve_wait',
  RSE_RESERVE_WAIT_PAID = 'rse_reserve_wait_paid',
  RSE_RESERVE_FAILED = 'rse_reserve_failed',
  ISSUANCE_WAIT = 'issuance_wait',
  ISSUED = 'issued',
}

type Statuses = {
  [key in Status]: string | JSX.Element
}

const statuses: Statuses = {
  reserve_canceled: 'Бронь отменена',
  reserve_payed_full: (
    <span>Автомобиль забронирован и&nbsp;оплачен полностью</span>
  ),
  reserve_payed_not_full: (
    <span>Автомобиль забронирован с&nbsp;частичной предоплатой</span>
  ),
  reserve_not_payed: <span>Автомобиль забронирован без&nbsp;предоплаты</span>,
  payment_wait: <span>Ожидает подтверждения от&nbsp;банка</span>,
  dealer_payment_wait: (
    <span>
      Банк подтвердил платеж, с&nbsp;вами свяжется дилер для&nbsp;подтверждения
      бронирования
    </span>
  ),
  rse_reserve_wait: 'Проверяем доступность автомобиля',
  rse_reserve_wait_paid:
    'Банк подтвердил платеж. Оплата прошла успешно. Передаем вашу заявку в дилерский центр.',
  rse_reserve_failed: <span>Что-то пошло не&nbsp;так</span>,
  issuance_wait: 'Ожидает выдачи',
  issued: 'Выдан',
}

type Result = {
  statusCode: string
  time: number
  payment: number
  id: string
  dateOfExpiry: string
  dealerInfo: IDealerInfo
}

export default ({
  statusCode,
  time,
  payment,
  id,
  dateOfExpiry,
  dealerInfo: { name: dealerName, address, city, remoteSale },
}: Result) => {
  const reserveСanceled = (
    <Fragment>
      <Description>
        Попробуйте забронировать еще раз, либо свяжитесь с&nbsp;дилерским
        центром.
      </Description>

      <StyledButton href="https://www.audi.ru/ru/web/ru/car-stock.html">
        Вернуться в каталог
      </StyledButton>
    </Fragment>
  )

  const contentReservePayedNotFull = (
    <Fragment>
      <Description weight="bold">
        {statusCode === 'rse_reserve_wait' ||
        statusCode === 'rse_reserve_wait_paid'
          ? 'Создана заявка'
          : 'Заявка'}{' '}
        {id}.{' '}
        {payment > 0 && (
          <span>
            {statusCode === 'payment_wait'
              ? 'Размер предоплаты'
              : 'Внесена предоплата'}{' '}
            {moneyFormat(payment)}
          </span>
        )}
      </Description>
      {statusCode !== 'reserve_payed_not_full' && (
        <Description weight="bold">
          В ближайшее время с вами свяжется представитель дилера.
        </Description>
      )}
      <Description>
        Наличие автомобиля и его конечная стоимость гарантированы после
        получения предоплаты. По&nbsp;вашему желанию на автомобиль может быть
        установлено дополнительное оборудование, что&nbsp;может привести
        к&nbsp;изменению стоимости. Также вы можете воспользоваться услугой
        страхования и кредитования.
      </Description>

      {!remoteSale && (
        <Description>
          Напоминаем, что для получения забронированного автомобиля вам{' '}
          <Text as="strong" weight="bold">
            необходимо подписать договор купли-продажи с&nbsp;дилерским центром
            не&nbsp;позднее&nbsp;
            {dateOfExpiry}
          </Text>
        </Description>
      )}
    </Fragment>
  )

  const contentReservePayedFull = (
    <Fragment>
      <Description weight="bold">
        Заявка {id}. Внесена полная оплата автомобиля {moneyFormat(payment)}{' '}
      </Description>
      <Description>
        Вы оплатили полную стоимость автомобиля. По&nbsp;вашему желанию
        на&nbsp;автомобиль может быть установлено дополнительное оборудование,
        что&nbsp;может привести к&nbsp;изменению стоимости. Менеджер дилерского
        центра {dealerName} свяжется с&nbsp;вами для&nbsp;согласования времени
        выдачи вашего автомобиля.
      </Description>
      {!remoteSale && (
        <Description>
          Напоминаем, что для получения забронированного автомобиля вам{' '}
          <Text as="strong" weight="bold">
            необходимо подписать договор купли-продажи с&nbsp;дилерским центром
            не&nbsp;позднее&nbsp;
            {dateOfExpiry}
          </Text>
        </Description>
      )}
    </Fragment>
  )

  const contentReserveNotPayed = (
    <Fragment>
      {time > 0 && (
        <Description weight="bold">осталось {convertTime(time)}</Description>
      )}

      <Description className="status__text">
        После отправки заявки с&nbsp;Вами свяжется представитель дилера.
        Вы&nbsp;сможете подробно узнать о&nbsp;процессе и&nbsp;заказать
        дополнительные услуги. В&nbsp;случае отправки заявки в&nbsp;нерабочее
        время дилерского центра, время обратной связи может быть увеличено.
      </Description>
      <Description weight="bold">
        Подробности относительно условий бронирования автомобиля, условий
        заключения и&nbsp;исполнения договора купли-продажи, а&nbsp;также все
        иные подробности уточняйте у&nbsp;{dealerName}.
      </Description>
      <Description>
        Информация о цене предоставлена Дилером.
        ООО&nbsp;«ФОЛЬКСВАГЕН&nbsp;Груп&nbsp;Рус» не&nbsp;устанавливает
        розничные цены и&nbsp;не&nbsp;несет ответственности за достоверность
        данной информации.
      </Description>
    </Fragment>
  )

  const dealerPaymentWait = (
    <Fragment>
      <Description weight="bold">
        Заявка {id}.{' '}
        {payment > 0 && <span>Внесена предоплата {moneyFormat(payment)}</span>}
      </Description>
      <Description>
        Наличие автомобиля и его конечная стоимость гарантированы после
        получения предоплаты. По&nbsp;вашему желанию на автомобиль может быть
        установлено дополнительное оборудование, что&nbsp;может привести
        к&nbsp;изменению стоимости. Также вы можете воспользоваться услугой
        страхования и кредитования.
      </Description>
      {!remoteSale && (
        <Description>
          Напоминаем, что для получения забронированного автомобиля вам{' '}
          <Text as="strong" weight="bold">
            необходимо подписать договор купли-продажи с&nbsp;дилерским центром
            не&nbsp;позднее&nbsp;
            {dateOfExpiry}
          </Text>
        </Description>
      )}
    </Fragment>
  )

  const rseReserveWaitPaid = (
    <Fragment>
      <Description weight="bold">
        Создана заявка {id}.{' '}
        {payment > 0 && <span>Внесена предоплата {moneyFormat(payment)}</span>}
      </Description>
      <Description weight="bold">
        В ближайшее время с вами свяжется представитель дилера.
      </Description>
      <Description>
        Наличие автомобиля и его конечная стоимость гарантированы после
        получения предоплаты. По&nbsp;вашему желанию на автомобиль может быть
        установлено дополнительное оборудование, что&nbsp;может привести
        к&nbsp;изменению стоимости. Также вы можете воспользоваться услугой
        страхования и кредитования.
      </Description>
      {!remoteSale && (
        <Description>
          Напоминаем, что для получения забронированного автомобиля вам{' '}
          <Text as="strong" weight="bold">
            необходимо подписать договор купли-продажи с&nbsp;дилерским центром
            не&nbsp;позднее&nbsp;
            {dateOfExpiry}
          </Text>
        </Description>
      )}
    </Fragment>
  )

  const rseReserveFailed = (
    <Fragment>
      <Description>
        Мы не смогли создать заявку на вашу бронь. Вероятнее всего автомобиль
        уже забронирован.
      </Description>
      <StyledButton href="https://www.audi.ru/ru/web/ru/car-stock.html">
        Вернуться в каталог
      </StyledButton>
    </Fragment>
  )

  const issuanceWait = (
    <Description>
      Выдача автомобиля производится по адресу: {city}, {address}
    </Description>
  )

  let resultContent = null

  switch (statusCode) {
    case 'reserve_canceled':
      resultContent = reserveСanceled
      break
    case 'reserve_payed_full':
      resultContent = contentReservePayedFull
      break
    case 'reserve_payed_not_full':
      resultContent = contentReservePayedNotFull
      break
    case 'reserve_not_payed':
      resultContent = contentReserveNotPayed
      break
    case 'payment_wait':
      resultContent = contentReservePayedNotFull
      break
    case 'dealer_payment_wait':
      resultContent = dealerPaymentWait
      break
    case 'rse_reserve_wait':
      resultContent = rseReserveWaitPaid
      break
    case 'rse_reserve_wait_paid':
      resultContent = rseReserveWaitPaid
      break
    case 'rse_reserve_failed':
      resultContent = rseReserveFailed
      break
    case 'issuance_wait':
      resultContent = issuanceWait
      break
    case 'issued':
      resultContent = null
      break
  }

  return (
    <Fragment>
      {statusCode !== 'rse_reserve_failed' &&
      statusCode !== 'reserve_canceled' ? (
        <StyledSuccess />
      ) : (
        <StyledFail />
      )}
      <Title>{statuses[statusCode as Status] || ''}</Title>
      {resultContent}
    </Fragment>
  )
}
