import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import { RadioButton, Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import { ICarInfo } from '@app/types'
import dataLayerHandlers from '@app/utils/metrics/dataLayerHandlers'
import { PaymentMethodType } from '@app/views/PaymentPage/types'

interface IProps {
  carInfo: ICarInfo
  paymentType: PaymentMethodType
  setPaymentType: (event: PaymentMethodType) => void
  paymentValue: number | number[]
  setPaymentValue: (e: number | number[]) => void
}

export const BookingMethod: FC<IProps> = ({
  carInfo,
  paymentType,
  setPaymentType,
  paymentValue,
  setPaymentValue,
}) => {
  const [lastPayment, setLastPayment] = useState(paymentValue)

  const handleChangePaymentType = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setPaymentType(event.target.id as PaymentMethodType)

      const isPayAtDealer = event.target.id === 'without-payment'
      setPaymentValue(isPayAtDealer ? 0 : lastPayment)

      dataLayerHandlers.booking.selectPaymentType(
        isPayAtDealer ? 'pay_at_dealer' : 'pay_online'
      )
    },
    [lastPayment]
  )

  useEffect(() => {
    if (paymentValue > 0) {
      setLastPayment(paymentValue)
    }
  }, [paymentValue])

  return (
    <Wrapper>
      <SectionTitle>Способ бронирования</SectionTitle>

      <RadioButton
        inputId="payment"
        name="booking-method"
        checked={paymentType === 'payment'}
        onChange={handleChangePaymentType}
        value="payment"
        disabled={
          carInfo.status && [7, 8, 9, 10].includes(carInfo.status?.code)
        }
      >
        <RadioButtonTitle>Внести предоплату</RadioButtonTitle>
        <Description>
          Наличие автомобиля и его максимальная стоимость гарантированы после
          получения предоплаты.
        </Description>
      </RadioButton>

      <Divider />

      <RadioButton
        data-testid="radio"
        inputId="without-payment"
        name="booking-method"
        checked={paymentType === 'without-payment'}
        onChange={handleChangePaymentType}
        value="without-payment"
      >
        <RadioButtonTitle>Забронировать без предоплаты</RadioButtonTitle>
        <Description>
          Резервирование автомобиля на 12 часов. Автомобиль может быть
          забронирован другим клиентом в дилерском центре.
        </Description>
      </RadioButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 64px;
  background: rgba(51, 51, 51, 0.2);

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 767px) {
    padding: 24px 12px;
    box-sizing: initial;
    margin-left: -12px;
    margin-right: -12px;
  }
`

const SectionTitle = styled(Text).attrs({ variant: 'order3' })`
  margin-bottom: 40px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 40px 0;
  background: #333333;
`

const RadioButtonTitle = styled(Text).attrs({ variant: 'order4' })`
  margin-bottom: 8px;
`

const Description = styled(Text).attrs({
  variant: 'copy1',
})`
  color: ${({ theme }) => theme.colors.text.inactive};
`
