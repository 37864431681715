import React, { FC } from 'react'

import { IDealerInfo } from '@app/types'

import {
  Description,
  ListItem,
  OrderedList,
  SimpleText,
  Subtitle,
  TermsAndDefinitions,
  Title,
  UnorderedList,
  Wrapper,
} from '../styled'

const TermsPaymentPDKP: FC<{ dealer: IDealerInfo }> = ({ dealer }) => {
  const {
    organizationName,
    ogrn,
    inn,
    kpp,
    address,
    city,
    emails,
    phones,
    legalAddress,
  } = dealer

  return (
    // created using https://word2cleanhtml.com/
    <Wrapper>
      <Title>Условия бронирования новых автомобилей марки Фольксваген</Title>
      <Description>
        (предварительный договор купли-продажи) для физических лиц, не
        являющихся индивидуальными предпринимателями (для потребителей)
      </Description>
      <TermsAndDefinitions>
        <Subtitle>Термины и&nbsp;определения</Subtitle>
        <SimpleText>
          Продавец&nbsp;&mdash; {organizationName} (ОГРН: {ogrn}, ИНН: {inn}),
          адрес:
          {legalAddress}
        </SimpleText>
        <SimpleText>
          Товар&nbsp;&mdash; Легковые автомобили марки Фольксваген.
        </SimpleText>
        <SimpleText>
          Клиент&nbsp;&mdash; полностью дееспособное физическое лицо, достигшее
          возраста 18 лет, а&nbsp;равно эмансипированные несовершеннолетние
          лица, имеющие намерение заключить с&nbsp;Продавцом договор
          купли-продажи Товара и&nbsp;с&nbsp;этой целью заключающие
          с&nbsp;Продавцом Предварительный договор купли-продажи Товара
          в&nbsp;соответствии с&nbsp;настоящими Условиями исключительно для
          личных, семейных, домашних и&nbsp;иных нужд, не&nbsp;связанных
          с&nbsp;осуществлением предпринимательской деятельности.
        </SimpleText>
        <SimpleText>
          <strong>Стороны</strong>&nbsp;&mdash; Продавец и&nbsp;Клиент, каждый
          в&nbsp;отдельности и совместно.
        </SimpleText>
        <SimpleText>
          <strong>Сайт</strong>&nbsp;&mdash; Интернет-сайт, имеющий адрес
          в&nbsp;сети Интернет <a href="https://www.audi.ru">www.audi.ru</a>,
          предназначенный для ознакомления Клиентов с&nbsp;предложенным
          Продавцом описанием Товара, для бронирования Товара и&nbsp;заключения
          Предварительных договоров купли-продажи в&nbsp;соответствии
          с&nbsp;настоящими Условиями.
        </SimpleText>
        <SimpleText>
          <strong>Владелец Сайта</strong>&nbsp;&mdash; ООО &laquo;ФОЛЬКСВАГЕН
          Груп Рус&raquo; (ОГРН: 1025005336564, ИНН: 5042059767)
        </SimpleText>
        <SimpleText>
          <strong>Заказ</strong>&nbsp;&mdash; оформленный в&nbsp;соответствии
          с&nbsp;настоящими Условиями запрос Клиента на&nbsp;бронирование
          и&nbsp;заключение Предварительного договора купли-продажи Товара,
          выбранного Клиентом на&nbsp;Сайте.
        </SimpleText>
        <SimpleText>
          <strong>Обеспечительный платеж</strong>&nbsp;&mdash; вносимый
          в&nbsp;порядке, предусмотренном настоящими Условиями, платеж Клиента
          в&nbsp;счет обеспечения исполнения обязательства Клиента
          по&nbsp;оплате Товара по Договору купли-продажи Товара, заключенному
          на&nbsp;условиях, предусмотренных Предварительным договором
          купли-продажи Товара.
        </SimpleText>
        <SimpleText>
          <strong>ГК&nbsp;РФ</strong>&nbsp;&mdash; Гражданский Кодекс Российской
          Федерации.
        </SimpleText>
        <SimpleText>
          <br />
        </SimpleText>
        <Subtitle>1. Общие положения</Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>1.1.</span> Настоящие Условия являются публичной офертой
              Продавца о&nbsp;заключении Предварительного договора купли-продажи
              в соответствии со&nbsp;статьей 435 и&nbsp;частью 2&nbsp;статьи
              437&nbsp;ГК РФ.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>1.2.</span> В&nbsp;соответствии с&nbsp;пунктом 3&nbsp;статьи
              438&nbsp;ГК РФ акцептом оферты является внесение Клиентом
              Обеспечительного платежа. Клиент подтверждает принятие изложенных
              ниже Условий нажатием кнопки &laquo;Оплатить&raquo;
              на&nbsp;последнем этапе оформления Заказа на&nbsp;Сайте.
              С&nbsp;момента подтверждения Продавцом получения Обеспечительного
              платежа от Клиента и&nbsp;получения Клиентом электронного чека,
              который содержит все данные о&nbsp;проведенной платежной
              транзакции, Предварительный договор купли-продажи считается
              заключенным на&nbsp;условиях, изложенных в оферте.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>1.3.</span> Продавец оставляет за&nbsp;собой право вносить
              изменения в&nbsp;настоящие Условия в&nbsp;одностороннем порядке.
              В&nbsp;отношениях между Продавцом и&nbsp;конкретным Клиентом
              применяются Условия, действующие на&nbsp;момент акцепта Клиентом
              Условий (п.&nbsp;1.2. Условий).
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>1.4.</span> В&nbsp;случае проведения стимулирующих
              мероприятий &mdash; акций, в&nbsp;условиях акций, размещаемых
              на&nbsp;Сайте, могут быть установлены специальные положения,
              регулирующие порядок оформления Заказа. При этом условия акций
              являются неотъемлемой частью настоящих Условий и&nbsp;подлежат
              применению для лиц, участвующих в акциях. Оформление Заказа
              в&nbsp;рамках акции и/или выполнение иных условий участия
              в&nbsp;акции означает согласие Клиента с&nbsp;условиями
              соответствующей акции.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>1.5.</span> Все информационные материалы о&nbsp;Товарах,
              представленные на&nbsp;Сайте, носят справочный характер
              и&nbsp;не&nbsp;могут в полной мере передавать достоверную
              информацию о&nbsp;свойствах и характеристиках Товара, включая
              цвета, размеры и&nbsp;формы. В&nbsp;случае возникновения
              у&nbsp;Клиента вопросов, касающихся свойств и&nbsp;характеристик
              Товара, перед оформлением Заказа Клиент должен обратиться за
              разъяснениями к&nbsp;Продавцу.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>2. Предмет Предварительного договора</Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>2.1.</span> В&nbsp;соответствии с&nbsp;настоящими Условиями
              Продавец и Клиент принимают на&nbsp;себя обязательство заключить
              в&nbsp;будущем Договор купли-продажи Товара, представленного
              на&nbsp;Сайте, в&nbsp;отношении которого Клиентом оформлен Заказ.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>2.2.</span> Предмет Договора купли-продажи (Товар),
              а&nbsp;также цена и&nbsp;количество Товара определяются Сторонами
              в&nbsp;Заказе, оформляемом в&nbsp;порядке, описанном
              в&nbsp;разделе 5&nbsp;настоящих Условий.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>
          3. Срок и&nbsp;порядок заключения Договора купли-продажи Товара
        </Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>3.1.</span> Стороны обязуются заключить Договор
              купли-продажи Товара в&nbsp;срок, не&nbsp;превышающий 7&nbsp;дней
              с&nbsp;момента заключения Предварительного договора купли-продажи
              Товара.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>3.2.</span> Продавец до&nbsp;истечения срока, указанного
              в&nbsp;п.&nbsp;3.1. настоящих Условий, обязуется направить Клиенту
              предложение о заключении Договора купли-продажи Товара
              по&nbsp;адресу электронной почты, указанному Клиентом при
              регистрации на&nbsp;Сайте.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>3.3.</span> Клиент обязуется не&nbsp;позднее последнего дня
              срока, указанного в&nbsp;п.&nbsp;3.1. настоящих Условий, явиться
              к&nbsp;Продавцу по&nbsp;адресу Продавца, указанному
              в&nbsp;настоящих Условиях, для подписания Договора купли-продажи
              Товара.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>3.4.</span> Договор купли-продажи Товара заключается
              исключительно после ознакомления Клиента с&nbsp;Товаром, имеющимся
              в наличии у&nbsp;Продавца, либо после ознакомления Клиента
              с&nbsp;образцами Товаров в&nbsp;месте нахождения Продавца.
              Во&nbsp;избежание сомнений, настоящие Условия не&nbsp;предполагают
              продажу Товаров дистанционным способом.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>4. Регистрация на&nbsp;Сайте</Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>4.1.</span> Для оформления Заказа Клиенту необходимо
              зарегистрироваться на&nbsp;Сайте, заполнив обязательные поля.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>4.2.</span> Продавец и&nbsp;Владелец Сайта не&nbsp;несут
              ответственности за&nbsp;точность, правильность
              и&nbsp;достоверность информации, предоставляемой Клиентом при
              регистрации.
            </SimpleText>
          </ListItem>
          <ListItem>
            <span>4.3.</span>{' '}
            <SimpleText>
              Клиент обязуется не&nbsp;сообщать третьим лицам логин
              и&nbsp;пароль, указанные при регистрации. В&nbsp;случае
              возникновения у&nbsp;Клиента подозрений относительно безопасности
              его логина и&nbsp;пароля или возможности
              их&nbsp;несанкционированного использования третьими лицами, Клиент
              обязуется незамедлительно уведомить об&nbsp;этом Продавца,
              направив электронное письмо по&nbsp;адресу:{' '}
              <a href={`mailto:${emails[0]}`}>{emails[0]}</a>. Продавец и
              Владелец Сайта не&nbsp;несут ответственности за&nbsp;убытки,
              которые Клиент может понести в&nbsp;результате того, что его логин
              и&nbsp;пароль стали известны третьему лицу не&nbsp;по&nbsp;вине
              Продавца и/или Владельца Сайта.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>4.4.</span> Регистрируясь на&nbsp;Сайте, Клиент соглашается
              с получением сообщений сервисного характера и/или
              push-уведомлений, направляемых на&nbsp;адрес электронной почты,
              указанный при регистрации на&nbsp;Сайте и/или при оформлении
              Заказа, а&nbsp;также посредством смс-сообщений и/или через Службу
              по&nbsp;работе с&nbsp;клиентами Продавца, о состоянии Заказа
              Клиента и/или о&nbsp;Товарах, добавленных Клиентом в
              &laquo;Избранное&raquo;.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>5. Оформление Заказа</Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>5.1.</span> При оформлении Заказа на&nbsp;Сайте Клиент
              должен указать следующую информацию:
              <UnorderedList>
                <ListItem>Ф.И.О. Клиента;</ListItem>
                <ListItem>Контактный телефон;</ListItem>
                <ListItem>Адрес электронной почты оформителя Заказа;</ListItem>
              </UnorderedList>
              а&nbsp;также заполнить все иные обязательные поля в&nbsp;форме
              Заказа на Сайте.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>6. Цена Товара и&nbsp;Обеспечительный платеж</Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>6.1.</span> Цена Товара указывается на&nbsp;Сайте
              и&nbsp;включает в&nbsp;себя все налоговые, таможенные и&nbsp;иные
              платежи и&nbsp;сборы, а&nbsp;также транспортировку Товара
              до&nbsp;места нахождения Продавца. Цена Товара, указанная
              на&nbsp;Сайте, может быть изменена Продавцом в&nbsp;одностороннем
              порядке в&nbsp;любое время.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>6.2.</span> Цена Товара действительна на&nbsp;момент
              оформления Заказа и&nbsp;нажатия кнопки &laquo;Оплатить&raquo;
              на&nbsp;последнем этапе оформления Заказа. После этого цена
              на&nbsp;заказанный Клиентом Товар, в&nbsp;отношении которого
              заключен Предварительный договор купли-продажи Товара, изменению
              не&nbsp;подлежит.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>6.3.</span> Заказ принимается в&nbsp;обработку только после
              внесения Клиентом Обеспечительного платежа и&nbsp;заключения
              Предварительного договора купли-продажи Товара в&nbsp;соответствии
              с&nbsp;п. 1.2. настоящих Условий.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>6.4.</span> При оформлении Заказа Клиент вносит
              Обеспечительный платеж, предназначенный для обеспечения исполнения
              обязательства Клиента по&nbsp;оплате Товара по&nbsp;Договору
              купли-продажи Товара, заключенному на&nbsp;условиях,
              предусмотренных Предварительным договором купли-продажи Товара.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>6.5.</span> Размер Обеспечительного платежа определяется
              Клиентом самостоятельно при оформлении Заказа,
              но&nbsp;не&nbsp;может быть менее 30&nbsp;000&nbsp;рублей.
            </SimpleText>
          </ListItem>
          <ListItem>
            <div>
              <SimpleText>
                <span>6.6.</span>
                Внесение Клиентом Обеспечительного платежа с&nbsp;целью
                заключения Предварительного договора купли-продажи Товара
                в&nbsp;соответствии с&nbsp;п. 1.2. настоящих Условий продажи
                может осуществляться следующими способами:
              </SimpleText>
              <OrderedList>
                <ListItem>
                  <SimpleText>
                    <span>6.6.1.</span> в&nbsp;форме онлайн оплаты (подробнее
                    в&nbsp;п.&nbsp;6.7) с&nbsp;помощью платежных карт Visa,
                    Мastercard, Maestro и&nbsp;МИР. Комиссия за&nbsp;перевод
                    денежных средств с&nbsp;Клиента не&nbsp;взимается.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.6.2.</span> с помощью банковского перевода по
                    реквизитам Продавца с указанием назначения платежа «оплата
                    обеспечительного платежа по предварительному договору за
                    автомобиль Фольксваген». Комиссия взимается с Клиента в
                    соответствии с условиями Банка и не включается в
                    Обеспечительный платеж.
                  </SimpleText>
                </ListItem>
              </OrderedList>
            </div>
          </ListItem>
          <ListItem>
            <div>
              <SimpleText>
                <span>6.7.</span>
                Порядок онлайн оплаты Обеспечительного Платежа с&nbsp;помощью
                банковских карт:
              </SimpleText>
              <OrderedList>
                <ListItem>
                  <SimpleText>
                    <span>6.7.1.</span> В&nbsp;соответствии с&nbsp;положением
                    Центрального Банка Российской Федерации
                    &laquo;Об&nbsp;эмиссии банковских карт и&nbsp;об операциях,
                    совершаемых с&nbsp;использованием платежных карт&raquo; от
                    24/12/2004 &#8470;&nbsp;266-П операции по&nbsp;банковским
                    картам совершаются держателем карты либо лицом,
                    уполномоченным на&nbsp;основании доверенности, оформленной
                    в&nbsp;соответствии с&nbsp;законодательством (нотариально
                    удостоверенная доверенность).
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.7.2.</span> При внесении Обеспечительного Платежа
                    банковской картой онлайн на&nbsp;Сайте после подтверждения
                    Заказа и нажатия кнопки &laquo;Оплатить&raquo; Клиент
                    перенаправляется на защищенную платежную страницу банка, где
                    необходимо внести данные банковской карты для совершения
                    платежа.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.7.3.</span> Все данные, введенные Клиентом на
                    платежной странице банка, защищены с&nbsp;использованием
                    технологий в&nbsp;соответствии с&nbsp;требованиями стандарта
                    безопасности PCI&nbsp;DSS и не&nbsp;передаются Продавцу,
                    Продавец не&nbsp;получает никаких сведений о&nbsp;банковской
                    карте Клиента.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.7.4.</span> Прием и&nbsp;обработка платежей с
                    использованием банковских карт проводится банком-эквайером,
                    определенным Продавцом. Продавец не&nbsp;осуществляет
                    обработку, в том числе сбор и&nbsp;хранение данных
                    банковских карт Клиентов.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.7.5.</span> Авторизация операций по&nbsp;банковским
                    картам осуществляется банком. Если у&nbsp;банка есть
                    основания полагать, что операция носит мошеннический
                    характер, то&nbsp;банк вправе отказать в&nbsp;осуществлении
                    данной операции. Мошеннические операции с&nbsp;банковскими
                    картами попадают под действие статьи 159 Уголовного кодекса
                    Российской Федерации, если иное не предусмотрено
                    законодательством Российской Федерации.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.7.6.</span> Сразу после совершения платежа Клиент
                    перенаправляется обратно на&nbsp;Сайт.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>6.7.7.</span> В&nbsp;качестве подтверждения получения
                    платежа Продавцом Клиент получает электронный чек, который
                    содержит все данные о&nbsp;проведенной платежной транзакции.
                  </SimpleText>
                </ListItem>
              </OrderedList>
            </div>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>6.8.</span>
              В&nbsp;случае заключения Сторонами Договора купли-продажи Товара,
              Обеспечительный платеж возвращается Продавцом Клиенту по
              письменному требованию Клиента в&nbsp;течение&nbsp;5 (пяти)
              рабочих дней с момента получения такого требования. В&nbsp;случае
              отсутствия указанного письменного требования Клиента сумма
              Обеспечительного платежа остается у&nbsp;Продавца
              и&nbsp;засчитывается в&nbsp;счет оплаты Клиентом Товара
              по&nbsp;Договору купли-продажи.
            </SimpleText>
            <SimpleText>
              <br />
            </SimpleText>
            <SimpleText>
              В&nbsp;случае если по&nbsp;любой причине Договор купли-продажи
              Товара не будет заключен Сторонами в&nbsp;течение срока,
              указанного в&nbsp;п.&nbsp;3.1. настоящих Условий, Товар считается
              свободным для продажи, обязательства, предусмотренные
              Предварительным договором, прекращаются, а&nbsp;Обеспечительный
              платеж подлежит возврату Клиенту на&nbsp;основании письменного
              требования Клиента, которое он&nbsp;должен оформить
              собственноручно и&nbsp;передать Продавцу по&nbsp;месту нахождения
              последнего, в&nbsp;течение&nbsp;5 (пяти) рабочих дней
              с&nbsp;момента получения такого требования. Если оплата
              Обеспечительного платежа была осуществлена с&nbsp;использованием
              банковской карты, то&nbsp;Продавец возвращает Клиенту сумму
              Обеспечительного платежа на&nbsp;банковскую карту, с&nbsp;которой
              был осуществлен платеж.
            </SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              <span>6.9.</span> Порядок и&nbsp;сроки оплаты стоимости Товара
              согласовываются Сторонами в&nbsp;Договоре купли-продажи Товара.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>7. Гарантия на&nbsp;Товар</Subtitle>
        <OrderedList>
          <ListItem>
            <SimpleText>
              <span>7.1.</span>
              Условия предоставления гарантии на&nbsp;Товар, а&nbsp;также
              гарантийные сроки изложены на&nbsp;Сайте{' '}
              <a
                href="https://www.audi.ru/ru/web/ru/owners/service-and-maintenance/warranty/manufacturer-warranty.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.audi.ru/ru/web/ru/owners/service-and-maintenance/warranty/manufacturer-warranty.html
              </a>
              , а&nbsp;также указываются в&nbsp;Договоре купли-продажи Товара.
            </SimpleText>
          </ListItem>
        </OrderedList>
        <Subtitle>
          8. Обработка персональных данных Клиента и&nbsp;защита
          конфиденциальной информации
        </Subtitle>
        <OrderedList>
          <ListItem>
            <div>
              <SimpleText>
                <span>8.1.</span>
                Согласие Клиента на&nbsp;обработку персональных данных
                и&nbsp;права Клиента
              </SimpleText>
              <OrderedList>
                <ListItem>
                  <SimpleText>
                    <span>8.1.1.</span>
                    При регистрации на&nbsp;Сайте Клиент предоставляет следующие
                    персональные данные: фамилию, имя, отчество, адрес
                    электронной почты, номер мобильного телефона.
                    <br />
                    При оформлении Заказа с&nbsp;целью заключения
                    Предварительного договора купли-продажи Клиент должен
                    дополнительно предоставить паспортные данные.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>8.1.2.</span> При регистрации на&nbsp;Сайте,
                    а&nbsp;также при оформлении Заказа Клиент выражает согласие
                    и&nbsp;разрешает Продавцу и&nbsp;ООО &laquo;ФОЛЬКСВАГЕН Груп
                    Рус&raquo; (248926, Россия, г. Калуга, ул. Автомобильная,
                    д.1; адрес Филиала в&nbsp;г. Москве (почтовый адрес):
                    117485, г. Москва, ул. Обручева, д. 30/1.)
                    (далее&nbsp;&mdash; Операторы) обрабатывать свои указанные
                    выше персональные данные и&nbsp;совершать со&nbsp;своими
                    персональными данными, следующие действия: сбор,
                    систематизацию, накопление, хранение (в&nbsp;электронном
                    виде и&nbsp;на&nbsp;бумажном носителе), уточнение
                    (обновление, изменение), использование, распространение
                    (в&nbsp;том числе передачу) персональных данных третьим
                    лицам, с&nbsp;которыми у&nbsp;Операторов имеются действующие
                    договоры, в&nbsp;рамках которых Операторы поручают обработку
                    персональных данных Клиента в&nbsp;нижеуказанных целях,
                    включая трансграничную передачу персональных данных,
                    обезличивание, блокирование, уничтожение,
                    с&nbsp;использованием средств автоматизации и&nbsp;без
                    использования таких средств.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>8.1.3.</span> Персональные данные Клиента,
                    предоставленные Клиентом при регистрации на&nbsp;Сайте
                    и&nbsp;оформлении Заказа, обрабатываются Операторами
                    с&nbsp;целями: регистрации/авторизации Клиента
                    на&nbsp;Сайте; обработки Заказов Клиента и&nbsp;для
                    выполнения своих обязательств перед Клиентом; учета
                    предоставленной информации в&nbsp;базах данных; оценки и
                    анализа работы Сайта; проведения статистических
                    исследований, а&nbsp;также исследований, направленных
                    на&nbsp;улучшение качества продукции и&nbsp;услуг; анализа
                    предпочтений Клиента и предоставления персональных
                    рекомендаций; проведения маркетинговых программ, в&nbsp;том
                    числе, для продвижения товаров, работ, услуг на&nbsp;рынке;
                    информирования Клиента о&nbsp;новых товарах и&nbsp;услугах
                    Продавца и&nbsp;его партнеров (например, посредством
                    отправки журналов, отправки приглашений на&nbsp;презентации
                    продуктов, сообщений о&nbsp;технических нововведениях,
                    предстоящих работах по&nbsp;сервисному обслуживанию,
                    условиях покупки нового автомобиля и&nbsp;т.д.)
                    с&nbsp;помощью различных средств связи, а&nbsp;именно
                    посредством: интернет; почтовых рассылок; сообщений
                    на&nbsp;адрес электронной почты; коротких текстовых
                    сообщений (SMS) и мультимедийных сообщений (MMS)
                    на&nbsp;номер телефона; а&nbsp;также посредством
                    использования информационно-коммуникационных сервисов, таких
                    как Viber, WhatsАpр и&nbsp;тому подобных; телефонных
                    звонков.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <SimpleText>
                    <span>8.1.4.</span> В&nbsp;целях проведения маркетинговых
                    программ, в&nbsp;том числе, для продвижения товаров, работ
                    и&nbsp;услуг, Операторы могут заключать соответствующие
                    договоры с&nbsp;третьими лицами относительно условий
                    предоставления Клиентам финансовых услуг. Клиент также дает
                    свое согласие на&nbsp;получение информации о предлагаемых
                    такими третьими лицами финансовых услугах. Указанная
                    информация может быть доведена до&nbsp;сведения Клиента как
                    Операторами, так и&nbsp;самими компаниями-партнерами
                    Операторов, предлагающими такие финансовые услуги, включая
                    следующие: ООО &laquo;Фольксваген Банк РУС&raquo; (117485,
                    г. Москва, ул. Обручева, д. 30/1, стр.1, ИНН: 7750005605,
                    лицензия ЦБ&nbsp;РФ &#8470;&nbsp;3500), ООО
                    &laquo;ФВ&nbsp;Груп Финанц&raquo; (Россия, 107045, г.
                    Москва, ул. Трубная, 12, ИНН: 7702349370); ООО
                    &laquo;Фольксваген Финансовые Услуги РУС&raquo; (Россия,
                    107045, г. Москва, ул. Трубная, 12, ИНН: 7702656396. Полный
                    перечень компаний&mdash;партнеров Операторов в области
                    предоставления финансовых услуг размещен на&nbsp;сайте
                    www.volkswagen.ru в&nbsp;разделе: &laquo;Кредит. Лизинг.
                    Страхование&raquo;.
                  </SimpleText>
                </ListItem>
                <ListItem>
                  <div>
                    {' '}
                    <SimpleText>
                      <span>8.1.5.</span>
                      Клиент имеет право:{' '}
                    </SimpleText>
                    <OrderedList>
                      <UnorderedList>
                        <ListItem>
                          <SimpleText>
                            на&nbsp;получение сведений о&nbsp;наличии своих
                            персональных данных у&nbsp;Операторов;
                          </SimpleText>
                        </ListItem>
                        <ListItem>
                          <SimpleText>
                            на&nbsp;ознакомление со&nbsp;своими персональными
                            данными (за исключением случаев, указанных
                            в&nbsp;п.5 ст.14 Федерального закона
                            от&nbsp;27/07/2006&nbsp;г. &#8470;&nbsp;152-ФЗ
                            &laquo;О персональных данных&raquo;);
                          </SimpleText>
                        </ListItem>
                        <ListItem>
                          <SimpleText>
                            потребовать от&nbsp;Операторов исключения или
                            исправления (уточнения) неверных или неполных
                            персональных данных, а&nbsp;также отозвать свое
                            согласие и&nbsp;потребовать блокирования или
                            уничтожения своих персональных данных путем
                            направления соответствующего письменного заявления
                            заказным письмом с&nbsp;описью вложения по почтовым
                            адресам Операторов;
                          </SimpleText>
                        </ListItem>
                        <ListItem>
                          <SimpleText>
                            принимать предусмотренные законом меры
                            по&nbsp;защите своих прав;
                          </SimpleText>
                        </ListItem>
                        <ListItem>
                          <SimpleText>
                            при нежелании Клиента получать сообщения
                            рекламно-информационного характера он&nbsp;должен
                            сообщить об&nbsp;этом в&nbsp;Информационный центр
                            Audi:{' '}
                            <a
                              href="https://www.audi.ru/ru/web/ru/contacts.html"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.audi.ru/ru/web/ru/contacts.html
                            </a>
                            . С&nbsp;момента изменения указанных настроек
                            получение рассылок от&nbsp;Операторов возможно
                            в&nbsp;течение 3&nbsp;дней, что обусловлено
                            особенностями работы и&nbsp;взаимодействия
                            информационных систем, а&nbsp;также условиями
                            договоров с контрагентами, осуществляющими
                            в&nbsp;интересах Операторов рассылки сообщений
                            рекламно-информационного характера. При этом для
                            отказа от&nbsp;получения сервисных сообщений,
                            направляемых на&nbsp;адрес электронной почты,
                            указанный при регистрации на&nbsp;Сайте,
                            а&nbsp;также посредством смс-сообщений и/или
                            push-уведомлений и&nbsp;через Службу по работе
                            с&nbsp;клиентами на&nbsp;номер телефона, указанный
                            при регистрации и/или при оформлении Заказа,
                            например, о состоянии Заказа, Клиент должен сообщить
                            об&nbsp;этом в Информационный центр Audi:{' '}
                            <a
                              href="https://www.audi.ru/ru/web/ru/contacts.html"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://www.audi.ru/ru/web/ru/contacts.html
                            </a>
                          </SimpleText>
                        </ListItem>
                      </UnorderedList>
                    </OrderedList>
                  </div>
                </ListItem>
                <ListItem>
                  <div>
                    <SimpleText>
                      <span>8.2.</span>
                      Обработка персональных данных Клиента и&nbsp;защита
                      конфиденциальной информации{' '}
                    </SimpleText>
                    <OrderedList>
                      <ListItem>
                        <SimpleText>
                          <span>8.2.1.</span>
                          Продавец обрабатывает персональные данные Клиента в
                          соответствии с&nbsp;утвержденной Продавцом политикой в
                          отношении обработки персональных данных, размещенной
                          на&nbsp;сайте Продавца{' '}
                          <a
                            href="https://www.audi.ru/ru/web/ru/confidential.html"
                            target="blank"
                          >
                            https://www.audi.ru/ru/web/ru/confidential.html
                          </a>
                          <br />
                          Владелец сайта, являющийся Оператором согласно&nbsp;п.
                          8.1.2. настоящих Условий продажи, обрабатывает
                          персональные данные Клиента в&nbsp;соответствии
                          с&nbsp;Политикой в&nbsp;отношении обработки
                          персональных данных, размещенной на&nbsp;Сайте.
                        </SimpleText>
                      </ListItem>
                      <ListItem>
                        <SimpleText>
                          <span>8.2.2.</span> Операторы при обработке
                          персональных данных принимает необходимые
                          и&nbsp;достаточные организационные и&nbsp;технические
                          меры для защиты персональных данных
                          от&nbsp;неправомерного доступа к&nbsp;ним, а также
                          от&nbsp;иных неправомерных действий в&nbsp;отношении
                          персональных данных.
                        </SimpleText>
                      </ListItem>
                      <ListItem>
                        <div>
                          <SimpleText>
                            <span>8.2.3.</span>
                            Операторы обязуются не&nbsp;передавать полученную от
                            Клиента информацию третьим лицам.
                            <br />
                            Не&nbsp;считается нарушением предоставление
                            Оператором(ами) информации:
                          </SimpleText>
                          <UnorderedList>
                            <ListItem>
                              <SimpleText>
                                агентам и&nbsp;третьим лицам, действующим
                                на&nbsp;основании договора
                                с&nbsp;Оператором(ами), для исполнения
                                обязательств перед Клиентом в&nbsp;рамках
                                заключенных с ним договоров;
                              </SimpleText>
                            </ListItem>
                            <ListItem>
                              <SimpleText>
                                третьим лицам в&nbsp;обезличенной форме
                                в&nbsp;целях оценки и&nbsp;анализа работы Сайта,
                                анализа Клиентских особенностей
                                и&nbsp;предпочтений покупателей;
                              </SimpleText>
                            </ListItem>
                            <ListItem>
                              <SimpleText>
                                в&nbsp;соответствии с&nbsp;обоснованными
                                и&nbsp;применимыми требованиями законодательства
                                Российской Федерации.
                              </SimpleText>
                            </ListItem>
                          </UnorderedList>
                        </div>
                      </ListItem>
                      <ListItem>
                        <SimpleText>
                          <span>8.2.4.</span> Продавец и&nbsp;Владелец сайта
                          вправе использовать технологию &laquo;cookies&raquo;.
                          &laquo;Cookies&raquo; не&nbsp;содержат
                          конфиденциальную информацию и&nbsp;не&nbsp;передаются
                          третьим лицам.
                        </SimpleText>
                      </ListItem>
                      <ListItem>
                        <SimpleText>
                          <span>8.2.5.</span> Продавец и&nbsp;Владелец сайта
                          получают информацию об&nbsp;ip-адресе посетителя Сайта
                          и&nbsp;сведения о том, по&nbsp;ссылке с&nbsp;какого
                          интернет-сайта посетитель пришел. Данная информация
                          не&nbsp;используется для установления личности
                          посетителя.
                        </SimpleText>
                      </ListItem>
                    </OrderedList>
                  </div>
                </ListItem>
              </OrderedList>
            </div>
          </ListItem>
        </OrderedList>
        <Subtitle>9. Реквизиты Продавца</Subtitle>
        <OrderedList>
          <ListItem>
            {' '}
            <SimpleText>Наименование: {organizationName}</SimpleText>
          </ListItem>
          <ListItem>
            {' '}
            <SimpleText>Юридический адрес: {legalAddress}</SimpleText>
          </ListItem>
          <ListItem>
            {' '}
            <SimpleText>
              Фактический адрес: (адрес салона){' '}
              <span data-testid="contract-address">
                {typeof city === 'string' ? city : city.name}
              </span>
              , {address}
            </SimpleText>
          </ListItem>
          <ListItem>
            {' '}
            <SimpleText>ОГРН: {ogrn}</SimpleText>
          </ListItem>
          <ListItem>
            <SimpleText>
              {' '}
              ИНН&nbsp;/ КПП: {inn}&nbsp;/ {kpp}
            </SimpleText>
          </ListItem>
          <ListItem>
            {' '}
            <SimpleText>Контактные телефоны: {phones.join(',')}</SimpleText>
          </ListItem>
          <ListItem>
            {' '}
            <SimpleText>Часы работы: 10:00&nbsp;&mdash; 22:00</SimpleText>
          </ListItem>
        </OrderedList>
      </TermsAndDefinitions>
    </Wrapper>
  )
}

export default TermsPaymentPDKP
