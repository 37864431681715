/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import ReactTooltip from 'react-tooltip'
import { Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import Info from '@app/components/icons/Info'
import { ICarInfo } from '@app/types'
import { getLogisticStatus } from '@app/utils/getLogisticStatus'
import dataLayerHandlers from '@app/utils/metrics/dataLayerHandlers'
import moneyFormat from '@app/utils/moneyFormat'
import parsePhoneWithMask from '@app/utils/parsePhoneWithMask'

interface IProps {
  step: string
  carInfo: ICarInfo
}

export const DealerInfo: FC<IProps> = ({ step, carInfo }) => {
  const isReservationStep = step === 'reservation'

  const {
    modelName,
    finalPrice,
    price,
    dealer,
    commercialNumber,
    amountPaid,
    confirmedPaid,
    reserveId,
    image,
    images,
    status,
  } = carInfo
  const payment = amountPaid + confirmedPaid

  const mainViewImage =
    images && images.find((item) => item.config === 'acon8c')

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  const logisticStatus =
    typeof status === 'number' ? getLogisticStatus(status) : status

  return (
    <Wrapper>
      {!isReservationStep && (
        <CarImageContainer>
          <CarImage
            data-testid="dealer-car-image"
            src={(mainViewImage && mainViewImage.url) || image}
            alt="car"
          />
        </CarImageContainer>
      )}
      <ModelName>Audi {modelName}</ModelName>
      <ValueText>Код автомобиля: {commercialNumber}</ValueText>

      {logisticStatus && (
        <StatusContainer>
          <ValueText variant="copy1">
            <StatusInfo color={logisticStatus.color}>
              {logisticStatus.headline}
              <Disclaimer>
                <IconWrapper
                  data-tip
                  data-for="status"
                  data-event="click focus"
                >
                  <Info />
                </IconWrapper>
                <ReactTooltip
                  place={isMobile ? undefined : 'bottom'}
                  type="dark"
                  effect="solid"
                  id="status"
                  backgroundColor="rgb(51, 51, 51)"
                  className="status-tooltip"
                  globalEventOff="click"
                >
                  <TooltipContent>{logisticStatus.text}</TooltipContent>
                </ReactTooltip>
              </Disclaimer>
            </StatusInfo>
          </ValueText>
        </StatusContainer>
      )}

      {/* <Label>Цена</Label>
      <ValueText>{moneyFormat(finalPrice || price)}</ValueText> */}

      {!isReservationStep && !!payment && (
        <>
          <Label>Предоплата</Label>
          <ValueText>{moneyFormat(payment)}</ValueText>
        </>
      )}

      {!isReservationStep && reserveId && (
        <>
          <Label>Номер брони</Label>
          <ValueText>{reserveId}</ValueText>
        </>
      )}

      <Label>Дилерский центр</Label>

      <ValueText variant="copy1">
        {dealer.name}
        <br />

        <StyledLink
          data-testid="dealer-phone"
          href={Array.isArray(dealer.phones) ? `tel:${dealer.phones[0]}` : ''}
          onClick={() => dataLayerHandlers.booking.clickDealerPhone(modelName)}
        >
          {Array.isArray(dealer.phones)
            ? parsePhoneWithMask(dealer.phones[0])
            : ''}
        </StyledLink>
      </ValueText>

      {dealer.organizationName ? (
        <Label data-testid="dealer-organization-name">
          {dealer.organizationName}
          <br />
          {dealer.inn ? `ИНН ${dealer.inn}` : ''}{' '}
          {dealer.ogrn ? `/ ОГРН ${dealer.ogrn}` : ''}
          <br />
          {dealer.legalAddress
            ? `Юридический адрес: ${dealer.legalAddress}`
            : ''}
        </Label>
      ) : (
        <br />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 24px;
`

const StyledLink = styled.a`
  text-decoration: none;
  border-bottom: 0 !important;
`

const CarImageContainer = styled.div`
  position: relative;
  height: 169px;
  margin-bottom: 24px;

  &::before {
    content: '';
    position: absolute;
    top: 40px;
    left: calc(50% - 50px);
    display: block;
    width: 100px;
    height: 50px;
    min-width: 100px;
    min-height: 50px;
    background: #999999;
    filter: blur(50px);
    border-radius: 26px;

    @media (max-width: 767px) {
      top: 30px;
      left: calc(50% - 25px);
      width: 50px;
      height: 25px;
      min-width: 50px;
      min-height: 25px;
    }
  }

  @media (max-width: 1024px) {
    height: auto;
  }
`

const CarImage = styled.img`
  position: relative;
  z-index: 1;
  height: 100%;

  @media (max-width: 1024px) {
    height: auto;
    width: 100%;
  }
`

const ModelName = styled(Text).attrs({
  variant: 'order3',
})`
  margin-bottom: 4px;
  white-space: nowrap;
`

const Label = styled(Text).attrs({
  variant: 'copy1',
})`
  margin-bottom: 4px;
  color: #666666;
`

const ValueText = styled(Text).attrs({
  variant: 'copy1',
})`
  margin-bottom: 24px;
`

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`

const StatusInfo = styled.div<{ color: string }>`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    margin-right: 8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${({ color }) => color};
  }

  .show {
    opacity: 1 !important;
    font-size: 12px !important;

    @media (max-width: 1024px) {
      max-width: 182px !important;
    }
  }
`

const TooltipContent = styled.div`
  color: white;
  font-family: AudiType;
`

const Disclaimer = styled.div`
  margin-left: 12px;

  svg {
    fill: white;
  }
`

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  padding: 5px;
`
