import { Layout, LayoutItem, Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

const ContentWrapper = styled(LayoutItem)`
  width: 100%;
  position: relative;

  @media (max-width: 767px) {
    .status-page {
      @media (max-width: 767px) {
        order: 0;
      }
    }
  }
`

const DealerInfoWrapper = styled(LayoutItem)`
  position: sticky;
  top: 24px;

  @media (max-width: 767px) {
    position: static;
  }
`

const PaymentDisplay = styled(LayoutItem)`
  width: 100%;

  @media (max-width: 767px) {
    order: 2;
  }
`

const Container = styled.main`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.utility.neutral[5]};
  padding-bottom: 120px;
  padding: 12px;
`

const StyledLayout = styled(Layout).attrs({
  align: 'center',
  justify: 'center',
  direction: 'column',
})`
  text-align: center;
`

const CarImageContainer = styled.div`
  position: relative;
  height: 390px;
  width: auto;
  margin-bottom: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 120px;
    left: calc(50% - 150px);
    display: block;
    width: 300px;
    height: 140px;
    min-width: 313px;
    min-height: 140px;
    background: #999999;
    filter: blur(100px);
    border-radius: 120px;

    @media (max-width: 767px) {
      top: 40px;
      left: calc(50% - 75px);
      width: 150px;
      height: 70px;
      min-width: 150px;
      min-height: 70px;
    }
  }

  @media (max-width: 1024px) {
    height: auto;
  }
`

const CarImage = styled.img`
  position: relative;
  z-index: 1;
  height: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
  }
`

const Title = styled(Text).attrs({
  forwardedAs: 'h1',
  variant: 'display2',
  weight: 'bold',
})`
  @media (max-width: 370px) {
    font-size: 28px;
    line-height: 44px;
  }
`

export {
  ContentWrapper,
  DealerInfoWrapper,
  PaymentDisplay,
  Container,
  StyledLayout,
  CarImageContainer,
  CarImage,
  Title,
}
