export const snakeToCamelCaseData = (obj: Record<string, any>) =>
  Object.keys(obj).reduce((acc: Record<string, any>, curr) => {
    const camelCaseKey = curr
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
      )

    acc[camelCaseKey] = obj[curr]

    return acc
  }, {})
