export default (tel: string) => {
  const initNumbers = tel.match(/[\d]/g)

  if (initNumbers) {
    const numbers = initNumbers.reverse()
    const result = []

    for (let i = 0; i < numbers.length; i += 1) {
      const element = numbers[i]

      if (i === 1 || i === 3) {
        result.push(`-${element}`)
      } else if (i === 6 || i === 9) {
        result.push(` ${element}`)
      } else {
        result.push(element)
      }
    }
    return `+${result.reverse().join('')}`
  }

  return ''
}
