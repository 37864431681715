import Cookies from 'js-cookie'

import { ICarInfo } from '@app/types'

const getDataForRequest = (
  carInfo: ICarInfo,
  payment: number | number[],
  uuid?: string | string[] | undefined
) => {
  const {
    vin,
    currentPrice,
    finalPrice,
    price,
    dealer: { externalId: dealerCode, code },
    id: pageId,
  } = carInfo

  const resultPrice = currentPrice || price || finalPrice

  const resultUrls = uuid
    ? {
        success_url: `${process.env.REACT_APP_APP_URL}/payment/${uuid}/retry-success`,
        fail_url: `${process.env.REACT_APP_APP_URL}/payment/${uuid}/retry-error`,
      }
    : {
        success_url: `${process.env.REACT_APP_APP_URL}/payment/${pageId}/success`,
        fail_url: `${process.env.REACT_APP_APP_URL}/payment/${pageId}/error`,
      }

  return {
    vin,
    dealer: dealerCode || code,
    payment,
    ...resultUrls,
    price: resultPrice,
    rights_granted: true,
    agree_receive_advertising: true,
    lead_type: 'Импортер',
    analytics_user_id: Cookies.get('rst-uid') || null,
  }
}

export default getDataForRequest
