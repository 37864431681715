import * as React from 'react'

const Success = React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.166016 26.4167C0.166016 11.9193 11.9185 0.166748 26.416 0.166748C40.9135 0.166748 52.666 11.9193 52.666 26.4167C52.666 40.9142 40.9135 52.6667 26.416 52.6667C11.9185 52.6667 0.166016 40.9142 0.166016 26.4167ZM26.416 1.33341C12.5629 1.33341 1.33268 12.5636 1.33268 26.4167C1.33268 40.2699 12.5629 51.5001 26.416 51.5001C40.2692 51.5001 51.4993 40.2699 51.4993 26.4167C51.4993 12.5636 40.2692 1.33341 26.416 1.33341ZM34.9449 20.2165L25.2133 34.2057L18.6535 27.6459L19.4785 26.8209L25.0521 32.3945L33.9872 19.5503L34.9449 20.2165Z"
      fill="white"
    />
  </svg>
))
export default Success
