import React from 'react'

import { useAuth } from '@app/hooks/useAuth'

import {
  Container,
  Description,
  StyledButton,
  StyledFail,
  Title,
} from '../styled'

export const Login = () => {
  const { login } = useAuth()

  return (
    <Container>
      <StyledFail />

      <Title>Не удалось вас авторизовать</Title>

      <Description>Попробуйте авторизоваться снова</Description>

      <StyledButton onClick={() => login()}>Авторизоваться</StyledButton>
    </Container>
  )
}
