import axios from 'axios'

export const sendRequest = (
  token: string | undefined,
  data: any,
  isRetry = false
) =>
  axios({
    method: 'post',
    timeout: 600000,
    url: `${process.env.REACT_APP_RESERVE_URL}/${isRetry ? 'pay' : 'create'}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${token}`,
    },
    data,
  })

export default sendRequest
