import React, { createContext, FC } from 'react'

import { useKeycloak } from '@app/hooks/useKeycloak'

export const AuthContext = createContext<{
  authToken?: string
  login(): void
  logout(): void
}>({} as any)

export const AuthContextProvider: FC = ({ children }) => {
  const authData = useKeycloak()

  if (!authData) return null

  return (
    <AuthContext.Provider value={{ ...authData }}>
      {children}
    </AuthContext.Provider>
  )
}
