import * as React from 'react'

const SberLogo = React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="122"
    height="20"
    viewBox="0 0 122 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M54.1817 5.72003L56.7077 3.85596H48.251V15.0221H56.7077V13.1581H50.6672V10.3071H55.8291V8.44305H50.6672V5.72003H54.1817Z"
        fill="#21A038"
      />
      <path
        d="M41.8625 8.20547H38.9338V5.72003H43.6198L46.1458 3.85596H36.5176V15.0221H41.5513C44.3702 15.0221 45.9994 13.7429 45.9994 11.5133C45.9994 9.37509 44.535 8.20547 41.8625 8.20547ZM41.4598 13.1581H38.9338V10.0695H41.4598C42.9974 10.0695 43.7113 10.5813 43.7113 11.6229C43.7113 12.6646 42.9425 13.1581 41.4598 13.1581Z"
        fill="#21A038"
      />
      <path
        d="M63.5534 3.85596H58.959V15.0221H61.3752V11.8605H63.5717C66.5005 11.8605 68.3309 10.3254 68.3309 7.85824C68.3309 5.39108 66.5005 3.85596 63.5534 3.85596ZM63.4985 9.99645H61.3569V5.72003H63.4985C65.0544 5.72003 65.8964 6.4876 65.8964 7.85824C65.8964 9.22889 65.0544 9.99645 63.4985 9.99645Z"
        fill="#21A038"
      />
      <path
        d="M32.3807 12.61C31.7401 12.9572 31.0262 13.14 30.294 13.14C28.1158 13.14 26.5416 11.5866 26.5416 9.4484C26.5416 7.31019 28.1158 5.75679 30.294 5.75679C31.0811 5.73852 31.8499 5.99437 32.4906 6.45125L34.2295 5.17198L34.1197 5.06233C33.1129 4.16684 31.7584 3.70996 30.2208 3.70996C28.5551 3.70996 27.0358 4.27649 25.9375 5.29991C24.8392 6.35988 24.2169 7.84017 24.2535 9.3753C24.2352 10.9287 24.8392 12.4273 25.9375 13.5238C27.0907 14.602 28.6283 15.2051 30.2025 15.1686C31.9414 15.1686 33.4607 14.5655 34.4858 13.469L32.9299 12.3176L32.3807 12.61Z"
        fill="#21A038"
      />
      <path
        d="M97.3801 3.87423V15.0404H99.7963V10.4716H104.94V15.0404H107.356V3.87423H104.94V8.46133H99.7963V3.87423H97.3801ZM93.225 15.0404H95.7693L91.0468 3.87423H88.5939L83.7798 15.0404H86.2143L87.1845 12.7926H92.3098L93.225 15.0404ZM87.9167 10.9285L89.7837 6.45105L91.541 10.9285H87.9167ZM112.774 10.5264H114.275L117.973 15.0221H121.085L116.142 9.24716L120.462 3.85596H117.698L114.111 8.64408H112.774V3.85596H110.358V15.0221H112.774V10.5264ZM75.799 8.22375V5.73831H82.1141V3.87423H73.3828V15.0404H78.4166C81.2355 15.0404 82.8646 13.7612 82.8646 11.5316C82.8646 9.39336 81.4002 8.22375 78.7278 8.22375H75.799ZM75.799 13.1763V10.0878H78.3251C79.8626 10.0878 80.5765 10.5995 80.5765 11.6412C80.5765 12.6829 79.826 13.1946 78.3251 13.1946H75.799V13.1763Z"
        fill="#21A038"
      />
      <path
        d="M16.694 3.78296C17.1333 4.34949 17.4994 4.97085 17.8106 5.62876L9.35383 11.8606L5.80273 9.63105V6.96286L9.33552 9.17416L16.694 3.78296Z"
        fill="#21A038"
      />
      <path
        d="M2.19633 9.4483C2.19633 9.32037 2.19633 9.21072 2.21463 9.0828L0.0729921 8.97314C0.0729921 9.11935 0.0546875 9.28382 0.0546875 9.43003C0.0546875 11.9886 1.09805 14.3095 2.78207 15.9908L4.30136 14.474C3.00173 13.1947 2.19633 11.422 2.19633 9.4483Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.33527 2.32107C9.46341 2.32107 9.57323 2.32107 9.70137 2.33934L9.81119 0.201137C9.66476 0.201137 9.50002 0.182861 9.35358 0.182861C6.79093 0.182861 4.46625 1.22455 2.78223 2.90588L4.30151 4.42272C5.58283 3.12518 7.37668 2.32107 9.33527 2.32107Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M9.33529 16.5758C9.20716 16.5758 9.09733 16.5758 8.9692 16.5575L8.85938 18.6957C9.00581 18.6957 9.17055 18.714 9.31699 18.714C11.8796 18.714 14.2043 17.6723 15.8883 15.991L14.3691 14.4741C13.0877 15.7899 11.3122 16.5758 9.33529 16.5758Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M13.362 3.56375L15.1741 2.22966C13.5816 0.950387 11.5498 0.164551 9.33496 0.164551V2.30276C10.8359 2.32103 12.2271 2.77791 13.362 3.56375Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M18.6338 9.44821C18.6338 8.88167 18.5789 8.33341 18.4874 7.78516L16.4922 9.26545C16.4922 9.32028 16.4922 9.39338 16.4922 9.44821C16.4922 11.5499 15.5769 13.4322 14.1309 14.7298L15.5769 16.3197C17.444 14.6384 18.6338 12.1712 18.6338 9.44821Z"
        fill="#21A038"
      />
      <path
        d="M9.33566 16.5758C7.23063 16.5758 5.34525 15.662 4.04563 14.2183L2.45312 15.662C4.15545 17.5444 6.60827 18.714 9.33566 18.714V16.5758Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M4.55762 4.16685L3.11156 2.5769C1.22618 4.2765 0.0546875 6.72539 0.0546875 9.44841H2.19633C2.19633 7.36503 3.11156 5.4644 4.55762 4.16685Z"
        fill="url(#paint5_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="2.56054"
        y1="15.7307"
        x2="0.374775"
        y2="9.42269"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1444" stopColor="#F2E913" />
        <stop offset="0.3037" stopColor="#E7E518" />
        <stop offset="0.5823" stopColor="#CADB26" />
        <stop offset="0.891" stopColor="#A3CD39" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="3.3927"
        y1="2.65797"
        x2="8.94681"
        y2="0.544793"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0592" stopColor="#0FA8E0" />
        <stop offset="0.5385" stopColor="#0099F9" />
        <stop offset="0.9234" stopColor="#0291EB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="8.73362"
        y1="16.89"
        x2="15.3533"
        y2="15.3963"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1226" stopColor="#A3CD39" />
        <stop offset="0.2846" stopColor="#86C339" />
        <stop offset="0.8693" stopColor="#21A038" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="8.89494"
        y1="1.53262"
        x2="14.4"
        y2="3.22845"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0566" stopColor="#0291EB" />
        <stop offset="0.79" stopColor="#0C8ACB" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="2.99767"
        y1="16.0093"
        x2="8.90902"
        y2="18.2144"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1324" stopColor="#F2E913" />
        <stop offset="0.2977" stopColor="#EBE716" />
        <stop offset="0.5306" stopColor="#D9E01F" />
        <stop offset="0.8023" stopColor="#BBD62D" />
        <stop offset="0.9829" stopColor="#A3CD39" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="1.68427"
        y1="9.70667"
        x2="4.04786"
        y2="3.56796"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0698" stopColor="#A3CD39" />
        <stop offset="0.2599" stopColor="#81C55F" />
        <stop offset="0.9216" stopColor="#0FA8E0" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="121.176" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
))
export default SberLogo
