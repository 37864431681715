import { Button, Checkbox, Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import Cross from '@app/components/icons/Cross'
import Success from '@app/components/icons/Success'

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 64px;
  margin-bottom: 48px;
  background: rgba(51, 51, 51, 0.8);

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 767px) {
    padding: 24px 12px;
    box-sizing: initial;
    margin-left: -12px;
    margin-right: -12px;
  }
`

export const ContainerRetry = styled.div`
  padding: 64px;
  margin-bottom: 24px;
  background: rgba(51, 51, 51, 0.2);
`

export const TitleRetry = styled.div`
  padding: 64px;
  margin-bottom: 24px;
  background: rgba(51, 51, 51, 0.2);
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 35px;
  overflow: hidden;

  div {
    margin: 0;
  }
`

export const PreparingTitle = styled(Text).attrs({
  forwardedAs: 'h3',
  variant: 'order3',
})`
  margin-bottom: 32px;
`

export const PreparingMessage = styled(Text).attrs({
  variant: 'copy2',
})`
  margin-bottom: 32px;
`

export const PreparingDescription = styled(Text).attrs({
  variant: 'copy1',
})`
  color: #b3b3b3;
`

export const Title = styled(Text).attrs({
  forwardedAs: 'h3',
  variant: 'order1',
})`
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 32px;
`

export const StyledSuccess = styled(Success)`
  margin-bottom: 35px;
`

export const StyledFail = styled(Cross)`
  padding: 16px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  margin-bottom: 35px;
`

export const Description = styled(Text).attrs({
  variant: 'copy1',
})`
  margin-bottom: 32px;
`

export const StyledButton = styled(Button).attrs({ variant: 'primary' })`
  margin-top: 35px;
`

export const AgreeLink = styled.span`
  margin-top: 64px;
  color: ${({ theme }) => theme.colors.utility.neutral[30]};
  text-decoration: underline;
`

export const CheckboxAgreeStyled = styled(Checkbox)`
  margin-top: 64px;

  @media (max-width: 1023px) {
    margin-top: 48px;
  }
`

export const BookingButtonStyled = styled(Button).attrs({ variant: 'primary' })`
  margin-top: 48px;
  margin-bottom: 80px;

  @media (max-width: 1023px) {
    margin-bottom: 0px;
  }
`
