import { Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

const UnorderedList = styled.ul`
  display: block;
  list-style: disc;
  padding-left: 16px;
`

const ListItem = styled.li`
  list-style: disc;
  padding-left: 16px;
`

const OrderedList = styled.ol`
  margin-bottom: 16px;

  & > li {
    display: block;

    span {
      margin-right: 4px;
    }
  }

  li:not(:last-child) {
    margin-bottom: 4px;
  }
`

const Wrapper = styled.div``

const SimpleText = styled(Text).attrs({ variant: 'copy2' })`
  color: #b3b3b3;
`

const Title = styled(Text).attrs({ variant: 'order3' })`
  margin-bottom: 16px;
`

const Subtitle = styled(Text).attrs({
  variant: 'order4',
})`
  color: #ffffff;
  margin-bottom: 16px;
`

const Description = styled(Text).attrs({ variant: 'copy1' })`
  margin-bottom: 24px;
`

const TermsAndDefinitions = styled.div`
  color: #b3b3b3;
`

export {
  UnorderedList,
  ListItem,
  OrderedList,
  Wrapper,
  SimpleText,
  Title,
  Subtitle,
  Description,
  TermsAndDefinitions,
}
