import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'

import { Loader } from '@app/components/Loader'
import { useKeycloak } from '@app/hooks/useKeycloak'
import { ICarInfo } from '@app/types'
import saveRedirectPage from '@app/utils/saveRedirectPage'
import { snakeToCamelCaseData } from '@app/utils/snakeToCamelCaseData'

import { Header } from './Displays'
import { Error } from './Error'
import { PaymentPageContent } from './PaymentPageContent'
import { Container } from './styled'
import { ParamTypes } from './types'

export const PaymentPage = () => {
  const params = useParams<ParamTypes>()
  const auth = useKeycloak()
  const { pathname } = useLocation()

  const { id, result } = params

  const [carInfo, setCarInfo] = useState<ICarInfo | null>(null)
  const [error, setError] = useState(false)

  const isStatusAfterPayment =
    result === 'status' ||
    result === 'retry-success' ||
    result === 'retry-error'

  const requestUrl = isStatusAfterPayment
    ? `${process.env.REACT_APP_STATUS_URL}?reserve_ids=${id}`
    : `${process.env.REACT_APP_DETAIL}/${id ? id.replace('%3D', '=') : ''}`

  useEffect(() => {
    if (
      result === 'status' &&
      auth &&
      !auth.authToken &&
      !Cookies.get('kdx_redirected')
    ) {
      saveRedirectPage(pathname)
      Cookies.set('kdx_redirected', 'status')

      auth.login()
    }
  }, [result, auth])

  useEffect(() => {
    if (result !== 'status' || auth.authToken)
      axios(
        `${requestUrl}`,
        isStatusAfterPayment
          ? {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                authorization: `Bearer ${auth.authToken}`,
              },
            }
          : {}
      )
        .then(({ data }) => {
          const resultData = isStatusAfterPayment
            ? {
                ...data.requests[0].props,
                ...data.requests[0].version,
                dealer: { ...data.requests[0].props.dealer.props },
              }
            : {
                ...snakeToCamelCaseData(data),
                dealer: snakeToCamelCaseData(data.dealer),
              }

          resultData.dealer.onlinePayment = false

          setCarInfo(resultData)
          setError(false)
        })
        .catch((err) => {
          console.error(err)

          setError(true)
        })
  }, [requestUrl, auth.authToken])

  useEffect(() => {
    Cookies.remove('VW_AUTH_ORDERS')
  }, [])

  return !error ? (
    <Container>
      <Header />

      {carInfo ? <PaymentPageContent carInfo={carInfo} /> : <Loader />}
    </Container>
  ) : (
    <Error />
  )
}
