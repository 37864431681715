import React, { useEffect } from 'react'

import {
  Container,
  Description,
  StyledButton,
  StyledFail,
  Title,
} from '../styled'

export const LimitRequests = () => {
  useEffect(() => {
    window.sessionStorage.removeItem('kdx_last_payment')
  }, [])

  return (
    <Container>
      <StyledFail />
      <Title>достигнут лимит количества бронирований</Title>
      <Description>
        Попробуйте забронировать еще раз через 24&nbsp;часа.
      </Description>

      <StyledButton href="https://www.audi.ru/ru/web/ru/car-stock.html">
        Вернуться в каталог
      </StyledButton>
    </Container>
  )
}
