import * as React from 'react'

const Cross = React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.400391 0.399902L16.7004 16.6999M16.6004 0.399902L0.400391 16.5999L16.6004 0.399902Z"
      stroke="white"
    />
  </svg>
))
export default Cross
