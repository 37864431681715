import React, { FC, useCallback, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

// import dataLayerHandlers from 'Metrics/dataLayerHandlers'
import { Loader } from '@app/components/Loader'
import { useAuth } from '@app/hooks/useAuth'
import usePaymentLink from '@app/hooks/usePaymentLink'
import getDataForRequest from '@app/utils/getDataForRequest'
import sendRequest from '@app/utils/sendRequest'

import { RetryBookingButton } from './components/RetryBookingButton'
import { DEFAULT_MIN_PAYMENT_VALUE } from '../../constants'
import { StepType } from '../../types'
import { CheckboxAgree } from '../Reservation/components'
import { PaymentAmount } from '../Reservation/components/PaymentAmount'
import RulesModal from '../Reservation/components/RulesModal'
import { LoaderWrapper, PreparingMessage, PreparingTitle } from '../styled'

interface IProps {
  setStep: (e: StepType) => void
  carInfo: any
  isStatusPage?: boolean
  uuid?: string | string[] | undefined
  payment: number | number[]
}

const Retry: FC<IProps> = ({
  payment,
  setStep,
  carInfo,
  // isStatusPage,
  uuid,
}) => {
  const { authToken } = useAuth()

  const minPayment = carInfo.dealer.min_payment || DEFAULT_MIN_PAYMENT_VALUE

  const [isLoading, setLoading] = useState(false)
  const [newPayment, setNewPayment] = useState<number | number[]>(
    DEFAULT_MIN_PAYMENT_VALUE
  )
  const [rulesModalIsOpen, setRulesModalIsOpen] = useState<boolean>(false)
  const [isTried, setIsTried] = useState<boolean | undefined>(undefined)
  const [isAgree, setIsAgree] = useState<boolean>(false)

  const { paymentLink, setPaymentLink } = usePaymentLink(payment)

  const {
    final_price: finalPrice,
    price,
    dealer: { max_payment: maxPayment },
  } = carInfo
  const resultPrice = price || finalPrice

  const data = getDataForRequest(carInfo, newPayment, uuid)

  const closeModal = useCallback(() => setRulesModalIsOpen(false), [])

  const handleSendRequest = () => {
    setLoading(true)

    sendRequest(authToken, data, true)
      .then(({ data: { reserve, result } }) => {
        window.sessionStorage.setItem('kdx_last_id', reserve.id)

        Cookies.set('isReserveSuccess', 'true')

        if (result && result.paymentUrl) {
          setPaymentLink(result.paymentUrl)
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.response) {
          // Request made and server responded
          const {
            body: {
              request_errors: requestErrors,
              system_errors: systemErrors,
            },
            status,
          } = error.response

          if (requestErrors && requestErrors.request_limit_exceeded) {
            setStep('limit')
          } else if (requestErrors || systemErrors) {
            setStep('system-error')

            window.sessionStorage.removeItem('kdx_last_payment')
            console.log('systemErrors', systemErrors)
          }
          if (status === 403) {
            setStep('login')
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request)
          setStep('error')
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
          setStep('error')
        }
      })
  }

  useEffect(() => {
    if (Number(payment) === 0) {
      setNewPayment(DEFAULT_MIN_PAYMENT_VALUE)
    } else {
      setNewPayment(payment)
    }
  }, [])

  useEffect(() => {
    const canSendRequest = window.sessionStorage.getItem('can_send_request')

    if (authToken && canSendRequest) {
      window.sessionStorage.removeItem('can_send_request')
      handleSendRequest()
    }
  }, [authToken])

  return (
    <>
      {!isLoading ? (
        <>
          {/* <Title>
            {isStatusPage ? 'Зафиксируй цену!' : 'попробовать ещё раз'}
          </Title>

          <Description>
            {isStatusPage
              ? 'Наличие автомобиля и его конечная стоимость гарантированы после получения предоплаты.'
              : 'Стоимость автомобиля не может быть изменена в сторону увеличения после внесения предоплаты.'}
          </Description> */}

          <PaymentAmount
            minPayment={minPayment}
            maxPayment={
              maxPayment !== 0 && maxPayment <= resultPrice
                ? maxPayment
                : resultPrice
            }
            paymentValue={newPayment}
            setPaymentValue={setNewPayment}
            isRetry
          />

          <CheckboxAgree
            paymentType="payment"
            setIsAgree={setIsAgree}
            carInfo={carInfo}
            setRulesModalIsOpen={setRulesModalIsOpen}
            setIsTried={setIsTried}
            isTried={isTried}
          />

          <RetryBookingButton
            isAgree={isAgree}
            setIsTried={setIsTried}
            sendRequest={handleSendRequest}
            newPayment={newPayment}
          />

          <RulesModal
            rulesModalIsOpen={rulesModalIsOpen}
            closeModal={closeModal}
            paymentValue={newPayment}
            carInfo={carInfo}
          />
        </>
      ) : (
        <>
          <PreparingTitle>ожидаем оплаты...</PreparingTitle>

          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>

          <PreparingMessage>
            Через несколько секунд вы будете перенаправлены на&nbsp;страницу
            оплаты. Если это не происходит автоматически,{' '}
            <a href={paymentLink}>нажмите&nbsp;сюда</a>
          </PreparingMessage>
        </>
      )}
    </>
  )
}

export default Retry
