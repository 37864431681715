import React, { FC, useEffect, useState } from 'react'

import { StepType } from '../../types'
import Retry from '../Retry'
import {
  Container,
  Description,
  StyledButton,
  StyledFail,
  Title,
} from '../styled'

interface IProps {
  setStep: (e: StepType) => void
  carInfo: any
  uuid: string | string[] | undefined
  payment: number | number[]
}

export const Error: FC<IProps> = ({ payment, setStep, carInfo, uuid }) => {
  const [id, setId] = useState('')

  useEffect(() => {
    const requestId = window.sessionStorage.getItem('kdx_last_id')

    if (requestId) {
      setId(requestId)
    }

    window.sessionStorage.removeItem('kdx_last_payment')
  }, [])

  return (
    <>
      {payment === 0 ? (
        <Container>
          <StyledFail />

          <Title>бронирование не&nbsp;удалось</Title>

          <Description>
            Что-то пошло не&nbsp;так. Попробуйте забронировать еще раз, либо
            свяжитесь с&nbsp;дилерским центром.
          </Description>

          <StyledButton href="https://www.audi.ru/ru/web/ru/car-stock.html">
            Вернуться в каталог
          </StyledButton>
        </Container>
      ) : (
        <>
          <Container>
            <StyledFail />

            <Title>{`оплата по заявке ${id} не прошла`}</Title>

            <Description>
              Мы не смогли списать деньги с&nbsp;вашей карты.
              <br />
              Автомобиль забронирован на&nbsp;24&nbsp;часа без&nbsp;предоплаты.
              <br />
              Попробуйте внести предоплату еще&nbsp;раз.
            </Description>

            {/* <StyledButton href="https://www.audi.ru/ru/web/ru/car-stock.html">
              Вернуться в каталог
            </StyledButton> */}
          </Container>

          <Retry
            payment={payment}
            setStep={setStep}
            carInfo={carInfo}
            uuid={uuid}
          />
        </>
      )}
    </>
  )
}
