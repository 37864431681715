import React from 'react'

import {
  Container,
  Description,
  StyledButton,
  StyledFail,
  Title,
} from '../styled'

export const SystemError = () => (
  <Container>
    <StyledFail />

    <Title>Что-то пошло не&nbsp;так</Title>

    <Description>
      Попробуйте забронировать еще раз, либо свяжитесь с&nbsp;дилерским центром.
    </Description>

    <StyledButton href="https://www.audi.ru/ru/web/ru/car-stock.html">
      Вернуться в каталог
    </StyledButton>
  </Container>
)
