import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Layout, Text } from '@audi/audi-ui-react'
import jwtDecode from 'jwt-decode'
import styled from 'styled-components'

import { useAuth } from '@app/hooks/useAuth'
import saveRedirectPage from '@app/utils/saveRedirectPage'

export const AutorizationInfo = () => {
  const { authToken, login } = useAuth()

  const location = useLocation()

  const [userData, setUserData] = useState<{
    name: string
    phone_number: string
    email: string
  } | null>(null)

  useEffect(() => {
    if (authToken) {
      setUserData(jwtDecode(authToken))
    }
  }, [authToken])

  return (
    <AutorizationWrapper>
      {userData ? (
        <>
          <Layout
            align={{ xs: 'start', m: 'start' }}
            justify="between"
            direction={{ xs: 'column', m: 'row' }}
          >
            <AutorizationDataWrapper>
              <AutorizationTitle>Личные данные</AutorizationTitle>

              <AutorizationData>{userData.name}</AutorizationData>
              <AutorizationData>{userData.phone_number}</AutorizationData>
              <AutorizationData>{userData.email}</AutorizationData>
            </AutorizationDataWrapper>

            <AutorizationPersonal>
              <a
                href={process.env.REACT_APP_IDP_PERSONAL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Изменить
              </a>
            </AutorizationPersonal>
          </Layout>
        </>
      ) : (
        <>
          <AutorizationTitle>Станьте владельцем Audi</AutorizationTitle>

          <Layout
            align={{ xs: 'start', m: 'center' }}
            justify="between"
            direction={{ xs: 'column', m: 'row' }}
          >
            <Text variant="copy1">
              Для того чтобы забронировать автомобиль, необходимо авторизоваться
            </Text>

            <AutorizationButton
              onClick={() => {
                saveRedirectPage(location.pathname)
                login()
              }}
            >
              Войти
            </AutorizationButton>
          </Layout>
        </>
      )}
    </AutorizationWrapper>
  )
}

const AutorizationDataWrapper = styled.div``

const AutorizationWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 64px;
  margin-bottom: 24px;
  background: rgba(51, 51, 51, 0.8);

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 767px) {
    padding: 24px 12px;
    box-sizing: initial;
    margin-left: -12px;
    margin-right: -12px;
  }
`

const AutorizationPersonal = styled(Text).attrs({ variant: 'copy1' })`
  a {
    border-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`

const AutorizationTitle = styled(Text).attrs({ variant: 'order4' })`
  margin-bottom: 8px;
`

const AutorizationButton = styled(Button).attrs({ variant: 'primary' })`
  @media (max-width: 1024px) {
    margin-left: 24px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 24px;
  }
`

const AutorizationData = styled(Text).attrs({ variant: 'copy1' })`
  margin-bottom: 4px;
`
