import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useAuth } from '@app/hooks/useAuth'

const Auth = () => {
  const history = useHistory()

  const auth = useAuth()
  const redirect = Cookies.get('VW_AUTH')

  useEffect(() => {
    if (auth.authToken && redirect) {
      const { pathname } = new URL(redirect)
      Cookies.remove('VW_AUTH')
      history.push(pathname)
    }
  }, [auth.authToken, redirect])

  return null
}

export default Auth
