import React, { ChangeEvent, FC, useCallback, useMemo } from 'react'
import NumberFormat from 'react-number-format'
import { Layout, LayoutItem, Slider, Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import {
  DEFAULT_MAX_PAYMENT_VALUE,
  DEFAULT_MIN_PAYMENT_VALUE,
  PAYMENT_STEP,
} from '@app/views/PaymentPage/constants'

interface IProps {
  minPayment: number
  maxPayment: number
  paymentValue: number | number[]
  setPaymentValue: (
    nextValue: number | number[],
    event: ChangeEvent<HTMLInputElement>
  ) => void
  isRetry?: boolean
}

export const PaymentAmount: FC<IProps> = ({
  paymentValue,
  setPaymentValue,
  minPayment,
  maxPayment,
  isRetry,
}) => {
  const { format } = useMemo(
    () =>
      new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        maximumSignificantDigits: 3,
      }),
    []
  )

  const handleSetValueInput = useCallback((event) => {
    let numberValue = Number(event.target.value.replace(/[^\d]/g, ''))

    if (minPayment && numberValue <= minPayment) {
      numberValue = minPayment
    }

    if (maxPayment && numberValue >= maxPayment) {
      numberValue = maxPayment
    }

    setPaymentValue(numberValue, event)
  }, [])

  return (
    <Wrapper>
      <SectionTitle>
        {isRetry ? 'Зафиксируйте цену автомобиля' : 'Введите сумму предоплаты'}
      </SectionTitle>

      <RangeLayout direction={{ xs: 'column', l: 'row' }}>
        <LayoutItem basis="50%">
          <Description>
            Гарантируем возврат 100% предоплаты на карту или счет, если вы
            передумаете.
          </Description>
        </LayoutItem>

        <LayoutItemWithRange basis={{ m: 'auto', l: '262px' }}>
          <Footnote>Сумма предоплаты</Footnote>

          <StyledNumberInput
            value={paymentValue <= minPayment ? minPayment : paymentValue}
            thousandSeparator={' '}
            suffix=" ₽"
            onBlur={handleSetValueInput}
          />

          <StyledSlider
            id="slider__basic"
            value={paymentValue}
            valueFormat={format}
            step={PAYMENT_STEP}
            min={minPayment || DEFAULT_MIN_PAYMENT_VALUE}
            max={maxPayment || DEFAULT_MAX_PAYMENT_VALUE}
            onChange={setPaymentValue}
            aria-label="slider"
          />

          <Footnote>Минимум {minPayment} ₽</Footnote>
        </LayoutItemWithRange>
      </RangeLayout>
    </Wrapper>
  )
}

const LayoutItemWithRange = styled(LayoutItem)`
  width: 100%;

  @media (max-width: 1023px) {
    margin-top: 32px;
  }
`

const RangeLayout = styled(Layout)`
  @media (max-width: 1023px) {
  }
`

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 64px;
  margin-top: 24px;
  background: rgba(51, 51, 51, 0.2);

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 767px) {
    padding: 24px 12px;
    box-sizing: initial;
    margin-left: -12px;
    margin-right: -12px;
  }
`

const StyledNumberInput = styled(NumberFormat)`
  margin-bottom: -24px;
  font-family: AudiType;
  color: #d9d9d9;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: -0.2px;
  background: transparent;
  border: 0;
`

const SectionTitle = styled(Text).attrs({ variant: 'order3' })`
  margin-bottom: 40px;

  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }
`

const Footnote = styled(Text).attrs({ variant: 'copy2' })`
  margin-bottom: 4px;
  color: #808080;
`

const Description = styled(Text).attrs({
  variant: 'copy1',
})`
  color: ${({ theme }) => theme.colors.text.inactive};
`

const StyledSlider = styled(Slider)`
  label {
    // margin-bottom: 26px;
    color: #808080;
    opacity: 0.6;
  }

  output,
  output + div {
    display: none;
  }

  input::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border: 1px solid #333333;
    border-radius: 50%;
    box-sizing: border-box;
    background: #ffffff;
    cursor: pointer;
    // margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }
`
