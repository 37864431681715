import * as React from 'react'
const SvgAlfabank = React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={33}
    height={50}
    className="alfabank_svg__d2vYs"
    viewBox="0 0 33 50"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 49.982v-6.825h32.61v6.825H0zm11.646-28.764h9.064L16.39 7.526h-.17l-4.573 13.692zm10.587-16.22l9.615 28.887h-7.115l-2.16-6.866H9.698l-2.33 6.867H.679l10.09-28.887C11.746 2.197 12.887 0 16.559 0s4.744 2.206 5.674 4.999z"
      clipRule="evenodd"
    />
  </svg>
))
export default SvgAlfabank
