import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@app/hooks/useAuth'
import { ICarInfo } from '@app/types'
import dataLayerHandlers from '@app/utils/metrics/dataLayerHandlers'
import saveRedirectPage from '@app/utils/saveRedirectPage'
import { PaymentMethodType, StepType } from '@app/views/PaymentPage/types'

import { BookingButtonStyled } from '../../../styled'

interface IProps {
  paymentType: PaymentMethodType
  setStep: (e: StepType) => void
  paymentValue: number | number[]
  carInfo: ICarInfo
  isAgree: boolean
  setIsTried: (e: boolean | undefined) => void
}

export const BookingButton: FC<IProps> = ({
  setStep,
  paymentType,
  paymentValue,
  carInfo,
  isAgree,
  setIsTried,
}) => {
  const location = useLocation()
  const { authToken, login } = useAuth()

  return (
    <BookingButtonStyled
      data-testid="booking-button"
      stretch
      onClick={() => {
        window.sessionStorage.setItem('kdx_last_payment', `${paymentValue}`)

        if (authToken && isAgree) {
          const isPayOnline = paymentType === 'payment'

          dataLayerHandlers.booking.submit(
            isPayOnline ? 'submit_pay_online' : 'submit_pay_at_dealer',
            carInfo.modelName
          )

          dataLayerHandlers.booking.selectAmountPrepaid(paymentValue)

          setStep('confirmation')
        } else if (isAgree) {
          login()
          saveRedirectPage(location.pathname)
        } else {
          setIsTried(true)
        }
      }}
    >
      {authToken
        ? 'Забронировать автомобиль'
        : 'Авторизоваться и забронировать'}
    </BookingButtonStyled>
  )
}
