import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AudiPlatformProvider } from '@audi/audi-ui-react'
import { ModalProvider } from 'styled-react-modal'

import { AuthContextProvider } from '@app/context/AuthContext'
import { GlobalStyles, theme } from '@app/styles'

import { Routes } from './routes'

function App() {
  return (
    <>
      <GlobalStyles />
      <AudiPlatformProvider theme={theme}>
        <AuthContextProvider>
          <ModalProvider>
            <Router>
              <Routes />
            </Router>
          </ModalProvider>
        </AuthContextProvider>
      </AudiPlatformProvider>
    </>
  )
}

export default App
