import React, { ChangeEvent, FC } from 'react'
import { Text } from '@audi/audi-ui-react'

import { ICarInfo } from '@app/types'
import dataLayerHandlers from '@app/utils/metrics/dataLayerHandlers'
import { PaymentMethodType } from '@app/views/PaymentPage/types'

import { AgreeLink, CheckboxAgreeStyled } from '../../../styled'

interface IProps {
  paymentType: PaymentMethodType
  setIsAgree: (e: boolean) => void
  carInfo: ICarInfo
  setRulesModalIsOpen: (e: boolean) => void
  isTried: boolean | undefined
  setIsTried: (e: boolean | undefined) => void
}

export const CheckboxAgree: FC<IProps> = ({
  paymentType,
  setIsAgree,
  carInfo,
  setRulesModalIsOpen,
  isTried,
  setIsTried,
}) => {
  return (
    <CheckboxAgreeStyled
      data-testid="agree-checkbox"
      inputId="agree"
      required
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setIsAgree(e.target.checked)
        setIsTried(undefined)

        if (e.target.checked) {
          dataLayerHandlers.booking.agree(carInfo.modelName)
        }
      }}
      invalid={isTried}
    >
      <Text variant="copy1">
        Я согласен на обработку запроса и соглашаюсь с{' '}
        <AgreeLink
          data-testid="agree-link"
          onClick={() => setRulesModalIsOpen(true)}
        >
          {paymentType === 'payment' ? 'условиями оферты' : 'правилами'}
        </AgreeLink>
      </Text>
    </CheckboxAgreeStyled>
  )
}
