import { useEffect, useState } from 'react'

import { REDIRECT_TIMEOUT } from '@app/views/PaymentPage/constants'

const usePaymentLink = (payment: number | number[]) => {
  const [paymentLink, setPaymentLink] = useState('')

  useEffect(() => {
    if (paymentLink) {
      setTimeout(
        () => window.location.replace(paymentLink),
        payment ? REDIRECT_TIMEOUT : 0
      )
    }
  }, [paymentLink])

  return {
    paymentLink,
    setPaymentLink,
  }
}

export default usePaymentLink
