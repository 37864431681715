import React, { FC } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { Link } from 'react-router-dom'
import { Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import { ArrowDownIcon } from '@app/components/icons'
import { Loader } from '@app/components/Loader'
import { IOrder } from '@app/types/order.types'
import moneyFormat from '@app/utils/moneyFormat'

export const Orders: FC<{
  isOpen: boolean
  openMenu: () => void
  closeMenu: () => void
  isLoading: boolean
  orderList: IOrder[]
}> = ({ isOpen, openMenu, closeMenu, isLoading, orderList }) => {
  return (
    <Container>
      <OutsideClickHandler onOutsideClick={closeMenu}>
        <Title onClick={openMenu}>
          <Text variant="copy2">Мои заказы</Text>
          <StyledArrow />
        </Title>

        {isOpen && (
          <OrdersWrapper>
            {isLoading ? (
              <Loader />
            ) : (
              <OrderList>
                {orderList && orderList.length ? (
                  orderList.map((item: any) => (
                    <Link
                      to={`/payment/${item.reserveId}/status`}
                      key={item.reserveId}
                    >
                      <OrderItem onClick={closeMenu}>
                        <MainInfo>
                          <Model>
                            <Text variant="copy1">Audi {item.modelName}</Text>
                          </Model>
                          <OrderInfo>
                            <OrderId>
                              <Text variant="copy2">{item.reserveId}</Text>
                            </OrderId>
                            <Delimiter variant="copy2"> | </Delimiter>
                            <OrderPrice>
                              <Text variant="copy2">
                                {moneyFormat(item.price)}
                              </Text>
                            </OrderPrice>
                          </OrderInfo>
                        </MainInfo>
                        <DealerInfo>
                          <Text variant="copy2">
                            {item.dealer.props.name}
                            <br />
                            {item.dealer.props.address}
                          </Text>
                        </DealerInfo>
                      </OrderItem>
                    </Link>
                  ))
                ) : (
                  <OrderItem>
                    <Text variant="copy2">В заказах пока ничего нет</Text>
                  </OrderItem>
                )}
              </OrderList>
            )}
          </OrdersWrapper>
        )}
      </OutsideClickHandler>
    </Container>
  )
}

const OrdersWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: calc(100% + 16px);
  right: 0;
  width: 440px;
  max-height: 400px;
  padding: 16px 0px;
  overflow: auto;
  background: #1f1f1f;
  display: flex;
  justify-content: center;

  @media (max-width: 440px) {
    width: 100%;
    position: fixed;
    top: 96px;
  }
`

const StyledArrow = styled(ArrowDownIcon)`
  margin-left: 11px;
`

const Container = styled.div`
  position: relative;
  display: flex;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const OrderList = styled.ul`
  width: 100%;
  padding: 0px;

  a {
    text-decoration: none;
  }
`

const OrderItem = styled.li`
  padding: 12px 16px;
  cursor: pointer;

  &: hover {
    background: #333333;
  }
`

const MainInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`

const OrderInfo = styled.div`
  display: flex;
  align-items: center;
`

const DealerInfo = styled.div`
  p {
    color: #666666;
  }
`

const Delimiter = styled(Text)`
  margin: 0 8px;
`

const Model = styled.div`
  text-transform: capitalize;
`

const OrderId = styled.span``

const OrderPrice = styled.span``
