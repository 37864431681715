import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Layout } from '@audi/audi-ui-react'

import { useAuth } from '@app/hooks/useAuth'
import { ICarInfo } from '@app/types'

import { BankDisclaimer } from './BankDisclaimer'
import { DealerInfo } from './DealerInfo'
import {
  Confirmation,
  Error,
  LimitRequests,
  Login,
  Reservation,
  Status,
  SystemError,
} from './Displays'
import {
  CarImage,
  CarImageContainer,
  ContentWrapper,
  DealerInfoWrapper,
  PaymentDisplay,
  StyledLayout,
  Title,
} from './styled'
import { ParamTypes, PaymentMethodType, StepType } from './types'

interface Props {
  carInfo: ICarInfo
}

export const PaymentPageContent: FC<Props> = ({ carInfo }) => {
  const { authToken } = useAuth()

  const params = useParams<ParamTypes>()

  const { id: pageId, result } = params

  const [step, setStep] = useState<StepType>('reservation')
  const [paymentType, setPaymentType] = useState<PaymentMethodType>(
    carInfo.dealer.onlinePayment &&
      carInfo.status &&
      [10, 11, 12].includes(carInfo.status?.code)
      ? 'payment'
      : 'without-payment'
  )
  const [paymentValue, setPaymentValue] = useState<number | number[]>(
    carInfo.dealer.onlinePayment ? carInfo.dealer.minPayment : 0
  )

  const { modelName, images, kitName } = carInfo

  const mainViewImage =
    images && images.find((item) => item.config === 'acon8c')

  useEffect(() => {
    switch (result) {
      case 'retry-success':
        setStep('success')
        break
      case 'retry-error':
        setStep('error')
        break
      case 'error':
        setStep('error')
        break
      case 'status':
        setStep('status')
        break
      default:
        setStep('reservation')
        break
    }
  }, [result])

  useEffect(() => {
    const storagePayment = window.sessionStorage.getItem('kdx_last_payment')
    const retryPayment = window.sessionStorage.getItem('kdx_retry_payment')

    if (carInfo && !storagePayment) {
      setPaymentValue(
        carInfo.dealer.onlinePayment ? carInfo.dealer.minPayment : 0
      )
    }

    if (carInfo && storagePayment && Number(storagePayment) >= 0) {
      setPaymentValue(Number(storagePayment))
      setStep('confirmation')
    }

    if (carInfo && retryPayment && Number(retryPayment) >= 0) {
      setPaymentValue(Number(retryPayment))
      window.sessionStorage.removeItem('kdx_retry_payment')
    }
  }, [carInfo])

  return (
    <>
      {step === 'reservation' && (
        <StyledLayout>
          <Title>
            Бронирование
            <br />
            Audi {modelName} {kitName || ''}
          </Title>
          <CarImageContainer>
            <CarImage
              src={mainViewImage && mainViewImage.url}
              alt={modelName}
            />
          </CarImageContainer>
        </StyledLayout>
      )}

      <Layout justify="center">
        <ContentWrapper basis={{ xs: 'auto', l: '1325px' }}>
          <Layout
            justify="around"
            direction={{ xs: 'column', m: 'row' }}
            align="stretch"
          >
            <PaymentDisplay
              basis={{ xs: 'auto', m: '450px', l: '662px' }}
              className={result ? 'status-page' : ''}
            >
              {step === 'reservation' && (
                <>
                  <Reservation
                    carInfo={carInfo}
                    setStep={setStep}
                    paymentValue={paymentValue}
                    setPaymentValue={setPaymentValue}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                  />
                  <BankDisclaimer
                    paymentProvider={carInfo.dealer.paymentProvider}
                  />
                </>
              )}

              {step === 'confirmation' && authToken && (
                <>
                  <Confirmation
                    paymentType={paymentType}
                    paymentValue={paymentValue}
                    setStep={setStep}
                    carInfo={carInfo}
                  />
                  <BankDisclaimer
                    paymentProvider={carInfo.dealer.paymentProvider}
                  />
                </>
              )}

              {step === 'error' && (
                <>
                  <Error
                    payment={paymentValue}
                    setStep={setStep}
                    carInfo={carInfo}
                    uuid={pageId}
                  />
                  <BankDisclaimer
                    paymentProvider={carInfo.dealer.paymentProvider}
                  />
                </>
              )}

              {step === 'limit' && <LimitRequests />}

              {step === 'login' && <Login />}

              {step === 'system-error' && <SystemError />}

              {step === 'status' && (
                <Status carInfo={carInfo} setStep={setStep} uuid={pageId} />
              )}
            </PaymentDisplay>

            <DealerInfoWrapper
              basis={{
                xs: 'auto',
                m: '250px',
                l: '300px',
                xl: '400px',
              }}
            >
              {carInfo && <DealerInfo step={step} carInfo={carInfo} />}
            </DealerInfoWrapper>
          </Layout>
        </ContentWrapper>
      </Layout>
    </>
  )
}
