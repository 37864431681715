import React from 'react'
import styled from 'styled-components'

import Cross from '@app/components/icons/Cross'
import { ICarInfo } from '@app/types'

import TermsPaymentPDKP from './TermsPaymentPDKP'
import TermsReserve from './TermsReserve'

interface IProps {
  closeModal: () => void
  paymentValue: number | number[]
  carInfo: ICarInfo
}

const ModalContent: React.FC<IProps> = ({
  closeModal,
  carInfo,
  paymentValue,
}) => {
  // const contract = carInfo.dealer.remote_sale ? (
  //   <TermsPaymentPDKP dealer={carInfo.dealer} /> // replace with dkd
  // ) : (
  //   <TermsPaymentPDKP dealer={carInfo.dealer} />
  // )
  const contract = <TermsPaymentPDKP dealer={carInfo.dealer} />
  const termsText = paymentValue === 0 ? <TermsReserve /> : contract

  return (
    <Container data-testid="rules">
      {termsText}

      <CloseButton data-testid="close-modal" onClick={closeModal}>
        <Cross />
      </CloseButton>
    </Container>
  )
}

export default ModalContent

const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    top: 16px;
    right: 12px;
  }
`

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 884px;
  padding: 48px;
  overflow: auto;
  background: #333333;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 24px;
  }
`
