import React from 'react'

import { SimpleText, TermsAndDefinitions, Title, Wrapper } from '../styled'

const TermsReserve = () => (
  <Wrapper>
    <Title>Правила</Title>
    <TermsAndDefinitions>
      <SimpleText>
        Обеспечение конфиденциальности и целостности персональных данных, защита
        персональных данных при их обработке являются для ООО «ФОЛЬКСВАГЕН Груп
        Рус» приоритетом. Настоящим мы гарантируем, что предоставляемые Вами
        персональные данные будут нами обработаны исключительно при условии
        соблюдения всех требований законодательства РФ о персональных данных.
        Собираемые персональные данные будут записываться, систематизироваться,
        накапливаться, храниться, уточняться и извлекаться с использованием баз
        данных, расположенных на территории Российской Федерации.
      </SimpleText>
      <br />
      <SimpleText>
        В случае если Вы согласны на обработку ООО «ФОЛЬКСВАГЕН Груп Рус» Ваших
        персональных данных, просим подтвердить Ваше согласие, поставив «V» в
        поле «Я согласен на обработку запроса и соглашаюсь с правилами».
      </SimpleText>
      <br />
      <SimpleText>
        Подтверждая согласие на обработку персональных данных, я разрешаю ООО
        «ФОЛЬКСВАГЕН Груп Рус» (248926, Калужская обл., г. Калуга, ул.
        Автомобильная, д. 1), филиалу ООО «ФОЛЬКСВАГЕН Груп Рус» в г. Москве
        (117485, г. Москва, ул. Обручева, д. 30/1), а также по их поручению
        третьим лицам осуществлять обработку моих персональных данных (фамилия,
        имя, телефон; адрес электронной почты; контактный адрес), включая сбор,
        систематизацию, накопление, хранение, уточнение, использование,
        распространение (в том числе трансграничную передачу), обезличивание,
        уничтожение персональных данных, с целями учета предоставленной мной
        информации в базах данных; проведения статистических исследований, а
        также исследований, направленных на улучшение качества продукции и
        услуг; проведения маркетинговых программ, в том числе для продвижения
        товаров, работ, услуг на рынке; информирования меня о товарах и услугах
        ООО «ФОЛЬКСВАГЕН Груп Рус» и его партнеров (например, посредством
        отправки журналов, отправки приглашений на презентации продуктов,
        сообщений о технических нововведениях, условиях покупки нового
        автомобиля и т.д.) с помощью различных средств связи (почтовая рассылка,
        электронная почта, телефон, SMS-/MMS-сообщения, сообщения посредством
        информационно-коммуникационных сервисов (Viber, WhatsApp и прочие). В
        целях проведения маркетинговых программ, в том числе для продвижения
        товаров, работ и услуг, ООО «ФОЛЬКСВАГЕН Груп Рус» может заключать
        соответствующие Договоры с третьими лицами относительно условий
        предоставления мне финансовых услуг. Подписанием настоящего документа я
        даю свое согласие на получение информации о предлагаемых такими третьими
        лицами финансовых услугах. Указанная информация может быть доведена до
        моего сведения как ООО «ФОЛЬКСВАГЕН Груп Рус», так и самими
        компаниями-партнерами ООО «ФОЛЬКСВАГЕН Груп Рус», предлагающими такие
        финансовые услуги, включая следующие: ООО «Фольксваген Банк РУС»
        (117485, г. Москва, ул. Обручева, д. 30/1, стр.1, ИНН: 7750005605,
        лицензия ЦБ РФ №3500), ООО «ФВ Груп Финанц» (Россия, 107045, г. Москва,
        ул. Трубная, 12, ИНН: 7702349370); ООО «Фольксваген Финансовые Услуги
        РУС» (Россия, 107045, г. Москва, ул. Трубная, 12, ИНН: 7702656396.
        Полный перечень компаний–партнеров ООО «ФОЛЬКСВАГЕН Груп Рус» в области
        предоставления финансовых услуг размещен на сайте www.audi.ru в разделе
        Audi Financial Services. Настоящее согласие на обработку персональных
        данных действует до момента его отзыва в соответствии со статьей 9
        Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных»
        посредством предоставления письменного заявления по почтовому адресу
        Филиала ООО «ФОЛЬКСВАГЕН Груп Рус» в г. Москве (г. Москва, ул. Обручева,
        д. 30/1, с пометкой «для службы безопасности») или в электронной форме
        посредством заполнения специальной формы «Обратная связь» на сайте
        www.audi.ru.
      </SimpleText>
    </TermsAndDefinitions>
  </Wrapper>
)

export default TermsReserve
