import { injectOSXFontSmoothing } from '@audi/audi-ui-react'
import { createGlobalStyle } from 'styled-components'
import { reset } from 'styled-reset'

export const GlobalStyles = createGlobalStyle`
  ${reset};
  ${injectOSXFontSmoothing(true)};
  body {
    margin: 0;
    background: #1A1A1A;
  }
  .rc-tooltip-content {
    max-width: 300px;
  }
`
