import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout, LayoutItem, Text } from '@audi/audi-ui-react'
import Cookies from 'js-cookie'
import styled from 'styled-components'

import { Header } from '@app/components/Header'
import { Loader } from '@app/components/Loader'
import { useKeycloak } from '@app/hooks/useKeycloak'
import saveRedirectPage from '@app/utils/saveRedirectPage'

import { OrdersPageContent } from './OrdersPageContent'

export const OrdersPage = () => {
  const auth = useKeycloak()
  const redirect = Cookies.get('VW_AUTH')
  const redirectForOrders = Cookies.get('VW_AUTH_ORDERS')

  const location = useLocation()

  useEffect(() => {
    if (
      auth &&
      location.pathname &&
      location.pathname.match('/orders') &&
      !redirectForOrders
    ) {
      Cookies.set(
        'VW_AUTH_ORDERS',
        `${process.env.REACT_APP_APP_URL}${location.pathname}`
      )

      saveRedirectPage(location.pathname)

      auth.login()
    }
  }, [auth.authToken, redirect, redirectForOrders])

  return (
    <Container>
      <Header />
      <Title>Мои заказы</Title>

      <ContentWrapper>
        <Layout
          justify="center"
          direction={{ xs: 'column', m: 'row' }}
          align="center"
        >
          {auth.authToken ? <OrdersPageContent /> : <Loader />}
        </Layout>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.main`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.utility.neutral[5]};
  padding-bottom: 120px;
  padding: 12px;
`

const Title = styled(Text).attrs({
  forwardedAs: 'h1',
  variant: 'display2',
  weight: 'bold',
})`
  text-align: center;
  margin-bottom: 32px;

  @media (max-width: 370px) {
    font-size: 28px;
    line-height: 44px;
  }
`

const ContentWrapper = styled(LayoutItem)`
  max-width: 767px;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 100%;
  }
`
