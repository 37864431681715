import React, { FC, memo } from 'react'
import { Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import AlfabankIcon from '@app/components/icons/Alfabank'
import MkbIcon from '@app/components/icons/Mkb'
import SberLogo from '@app/components/icons/SberLogo'

export const BankDisclaimer: FC<{ paymentProvider?: string }> = memo(
  ({ paymentProvider }) => {
    return (
      <Wrapper>
        {paymentProvider === 'sberbank' && (
          <>
            <FirstRow>
              <StyledLogo>
                <SberLogo />
              </StyledLogo>

              <FirstRowText>
                Безопасность проведения платёжных транзакций обеспечивает ПАО
                &laquo;Сбербанк&raquo;, генеральная лицензия
                на&nbsp;осуществление банковских операций
                от&nbsp;11&nbsp;августа 2015&nbsp;года. Регистрационный
                номер&nbsp;&mdash; 1481.
              </FirstRowText>
            </FirstRow>
            <DisclaimerText>
              В&nbsp;случае если Ваш банк поддерживает технологию безопасного
              проведения интернет-платежей Verified By&nbsp;Visa или MasterCard
              Secure Code, для проведения платежа также может потребоваться ввод
              специального пароля. Способы и&nbsp;возможность получения паролей
              для совершения интернет-платежей Вы&nbsp;можете уточнить
              в&nbsp;банке, выпустившем карту. Настоящий сайт поддерживает
              256-битное шифрование.
            </DisclaimerText>
            <DisclaimerText>
              Конфиденциальность сообщаемой персональной информации
              обеспечивается ПАО &laquo;Сбербанк России&raquo;. Введенная
              информация не&nbsp;будет предоставлена третьим лицам
              за&nbsp;исключением случаев, предусмотренных законодательством РФ.
              Проведение платежей по&nbsp;банковским картам осуществляется
              в&nbsp;строгом соответствии с&nbsp;требованиями платежных систем
              Visa Int.&nbsp;и&nbsp;MasterCard Europe Sprl.
            </DisclaimerText>
          </>
        )}

        {paymentProvider === 'alfabank' && (
          <>
            <FirstRow>
              <StyledLogo>
                <AlfabankIcon />
              </StyledLogo>

              <FirstRowText>
                Безопасность проведения платёжных транзакций обеспечивает
                АО&nbsp;«Альфа-Банк», генеральная лицензия Банка России
                №&nbsp;1326 от&nbsp;16&nbsp;января&nbsp;2015&nbsp;г.
              </FirstRowText>
            </FirstRow>

            <DisclaimerText>
              Услуга оплаты через интернет осуществляется в соответствии
              с&nbsp;Правилами международных платежных систем Visa, MasterCard
              и&nbsp;Платежная система «Мир» на&nbsp;принципах соблюдения
              конфиденциальности и&nbsp;безопасности совершения платежа,
              для&nbsp;чего используются самые современные методы проверки,
              шифрования и&nbsp;передачи данных по&nbsp;закрытым каналам связи.
              Ввод данных банковской карты осуществляется на&nbsp;защищенной
              платежной странице АО&nbsp;«АЛЬФА-БАНК».
            </DisclaimerText>
          </>
        )}

        {paymentProvider === 'mkb' && (
          <>
            <FirstRow>
              <StyledLogo>
                <MkbIcon />
              </StyledLogo>

              <FirstRowText>
                При оплате заказа банковской картой, обработка платежа
                происходит на авторизационной странице ПАО «МОСКОВСКИЙ КРЕДИТНЫЙ
                БАНК», где Вам необходимо ввести данные Вашей банковской карты.
              </FirstRowText>
            </FirstRow>
            <DisclaimerText>
              Для дополнительной аутентификации держателя карты используется
              протокол 3D Secure. Если Ваш Банк поддерживает данную технологию,
              Вы будете перенаправлены на сервер Вашего Банка для дополнительной
              идентификации. Информацию о правилах и методах дополнительной
              идентификации уточняйте в Банке, выдавшем Вам банковскую карту.
            </DisclaimerText>

            <DisclaimerText>
              К оплате принимаются платежные карты: VISA Inc, MasterCard
              WorldWide и Мир.
            </DisclaimerText>
          </>
        )}

        <PaymentSystem>
          <Mir />

          <Visa />

          {paymentProvider === 'sberbank' && <Maestro />}

          <Mastercard />
        </PaymentSystem>
      </Wrapper>
    )
  }
)

const Wrapper = styled.div`
  margin-top: 80px;
`

const FirstRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`

const FirstRowText = styled(Text).attrs({ variant: 'copy2' })`
  color: #808080;
`

const StyledLogo = styled.div`
  margin-right: 24px;
`

const DisclaimerText = styled(Text).attrs({ variant: 'copy2' })`
  margin-bottom: 32px;
  color: #808080;
`

const PaymentSystem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const PaymentSystemItem = styled.div`
  display: flex;
  height: 25px;
  margin-right: 24px;
  background-repeat: no-repeat;
  background-size: contain;

  &:last-child {
    margin-right: 0;
  }
`

const Mir = styled(PaymentSystemItem)`
  width: 43px;
  background-image: url("data:image/svg+xml,%3Csvg width='43' height='12' viewBox='0 0 43 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.32254 1.575L7.73997 7.0875H7.46923L5.88667 1.575C5.61592 0.642187 4.76629 0 3.79529 0H0V12H3.79996V4.9078H4.07074L6.24614 12H8.96307L11.1338 4.9078H11.4046V12H15.2045V0H11.4092C10.4429 0 9.59327 0.642187 9.32254 1.575ZM30.3623 12H34.1856V8.45156H38.0325C40.0444 8.45156 41.7577 7.3078 42.4205 5.7H30.3623V12ZM20.9138 7.0922L23.5887 1.26562C23.9436 0.496875 24.7138 0 25.5634 0H28.7892V12H24.9893V4.9125H24.7184L22.0435 10.7343C21.6887 11.5031 20.9185 12 20.0689 12H16.8431V0H20.6431V7.0922H20.9138Z' fill='%23006848'/%3E%3Cpath d='M38.4091 0C39.5061 0 40.5004 0.421875 41.252 1.11562C42.0923 1.88438 42.6198 2.99064 42.6198 4.22344C42.6198 4.47656 42.5918 4.725 42.5498 4.96875H35.4914C33.1012 4.96875 31.0752 3.39375 30.3936 1.21875C30.3843 1.19531 30.3797 1.16719 30.3703 1.13906C30.3516 1.07343 30.3376 1.00312 30.319 0.9375C30.2489 0.632811 30.2023 0.323436 30.1836 0H38.4091Z' fill='url(%23paint0_linear)'/%3E%3C/g%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='42.6199' y1='13.0707' x2='30.2597' y2='13.0707' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%231E5CD8'/%3E%3Cstop offset='1' stop-color='%2302AFFF'/%3E%3C/linearGradient%3E%3CclipPath id='clip0'%3E%3Cpath d='M0 0H42.6207V12H0V0Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
`

const Visa = styled(PaymentSystemItem)`
  width: 26px;
  background-image: url("data:image/svg+xml,%3Csvg width='26' height='16' viewBox='0 0 26 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M24.5038 16H1.88124C1.18116 16 0.621094 15.4372 0.621094 14.7337V1.26633C0.621094 0.562814 1.18116 0 1.88124 0H24.5038C25.2039 0 25.764 0.562814 25.764 1.26633V14.7337C25.764 15.4372 25.1839 16 24.5038 16Z' fill='%231A1F70'/%3E%3Cpath d='M13.1223 5.56783L12.0822 10.4321H10.822L11.8621 5.56783H13.1223ZM18.3829 8.72361L19.043 6.89446L19.423 8.72361H18.3829ZM19.783 10.4321H20.9432L19.9231 5.56783H18.8629C18.6229 5.56783 18.4229 5.70853 18.3229 5.92964L16.4427 10.4321H17.7628L18.0228 9.70853H19.623L19.783 10.4321ZM16.5227 8.84421C16.5227 7.55778 14.7625 7.49748 14.7625 6.91456C14.7625 6.73366 14.9225 6.55275 15.3025 6.51255C15.4825 6.49245 15.9826 6.47235 16.5427 6.73366L16.7627 5.70853C16.4627 5.60803 16.0826 5.48743 15.5826 5.48743C14.3424 5.48743 13.4823 6.15074 13.4623 7.09547C13.4623 7.79898 14.0824 8.18089 14.5624 8.4221C15.0425 8.66331 15.2225 8.80401 15.2025 9.02512C15.2025 9.34672 14.8225 9.48743 14.4624 9.50753C13.8224 9.50753 13.4623 9.32662 13.1823 9.20602L12.9423 10.2512C13.2423 10.3919 13.7824 10.5126 14.3424 10.5126C15.6426 10.5126 16.5027 9.86934 16.5227 8.84421ZM11.3221 5.56783L9.30184 10.4321H7.98169L6.98158 6.55275C6.92157 6.31155 6.86157 6.23115 6.68155 6.13064C6.38151 5.96984 5.88146 5.80903 5.44141 5.70853L5.48141 5.56783H7.62165C7.90169 5.56783 8.14171 5.74873 8.20172 6.07034L8.72178 8.88441L10.0219 5.56783H11.3221Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath d='M0.621094 0H25.764V16H0.621094V0Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
`

const Maestro = styled(PaymentSystemItem)`
  width: 26px;
  background-image: url("data:image/svg+xml,%3Csvg width='27' height='16' viewBox='0 0 27 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M16.9684 1.77759H10.0234V14.3569H16.9684V1.77759Z' fill='%237673C0'/%3E%3Cpath d='M10.5737 8.00065C10.5724 6.789 10.8447 5.59295 11.3699 4.50305C11.8951 3.41316 12.6595 2.458 13.6052 1.70991C12.4343 0.782453 11.0281 0.205724 9.54735 0.0456355C8.0666 -0.114453 6.57103 0.148555 5.23158 0.804604C3.89212 1.46065 2.76282 2.48328 1.97274 3.75559C1.18266 5.02791 0.763672 6.49858 0.763672 7.99954C0.763672 9.50049 1.18266 10.9712 1.97274 12.2435C2.76282 13.5158 3.89212 14.5384 5.23158 15.1945C6.57103 15.8505 8.0666 16.1135 9.54735 15.9534C11.0281 15.7934 12.4343 15.2166 13.6052 14.2892C12.6598 13.5413 11.8956 12.5865 11.3704 11.497C10.8452 10.4075 10.5727 9.21192 10.5737 8.00065Z' fill='%23EB001B'/%3E%3Cpath d='M25.6158 12.9771V12.7282H25.7194V12.6749H25.457V12.7282H25.5607V12.986L25.6158 12.9771ZM26.1251 12.9771V12.666H26.0457L25.9531 12.8882L25.8605 12.666H25.7789V12.9771H25.8362V12.7438L25.9222 12.946H25.9818L26.0677 12.7438V12.9793L26.1251 12.9771Z' fill='%2300A1DF'/%3E%3Cpath d='M26.3913 8.00158C26.3909 9.50225 25.9717 10.9726 25.1816 12.2445C24.3915 13.5165 23.2623 14.5388 21.9231 15.1947C20.5839 15.8506 19.0886 16.1136 17.6081 15.9536C16.1276 15.7936 14.7216 15.2172 13.5508 14.2901C14.4958 13.5416 15.2598 12.5865 15.7852 11.497C16.3105 10.4074 16.5835 9.21185 16.5835 8.00046C16.5835 6.78908 16.3105 5.59351 15.7852 4.50396C15.2598 3.41442 14.4958 2.45936 13.5508 1.71083C14.7216 0.783088 16.1278 0.206078 17.6087 0.0457598C19.0895 -0.114558 20.5852 0.148284 21.9248 0.80424C23.2645 1.4602 24.3939 2.48279 25.1841 3.75515C25.9744 5.02751 26.3934 6.49828 26.3935 7.99935L26.3913 8.00158Z' fill='%2300A1DF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath d='M0.763672 0H26.4479V16H0.763672V0Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
`

const Mastercard = styled(PaymentSystemItem)`
  width: 26px;
  background-image: url("data:image/svg+xml,%3Csvg width='27' height='16' viewBox='0 0 27 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M17.128 1.77783H9.48633V14.3554H17.128V1.77783Z' fill='%23FF5F00'/%3E%3Cpath d='M10.2573 7.99732C10.2563 6.78621 10.5288 5.59076 11.054 4.50143C11.5792 3.41209 12.3434 2.45742 13.2888 1.70967C12.1179 0.782343 10.7117 0.205695 9.23094 0.0456288C7.75019 -0.114437 6.25462 0.148537 4.91517 0.804495C3.57571 1.46045 2.44641 2.48293 1.65633 3.75507C0.866249 5.02721 0.447266 6.49768 0.447266 7.99843C0.447266 9.49918 0.866249 10.9696 1.65633 12.2418C2.44641 13.5139 3.57571 14.5364 4.91517 15.1924C6.25462 15.8483 7.75019 16.1113 9.23094 15.9512C10.7117 15.7912 12.1179 15.2145 13.2888 14.2872C12.3431 13.5392 11.5787 12.5842 11.0535 11.4944C10.5283 10.4047 10.256 9.2088 10.2573 7.99732Z' fill='%23EB001B'/%3E%3Cpath d='M25.2928 12.9777V12.72H25.403V12.6666H25.1406V12.72H25.2442V12.9777H25.2928ZM25.802 12.9777V12.6666H25.7227L25.6301 12.8888L25.5375 12.6666H25.4581V12.9777H25.5154V12.7422L25.6014 12.9444H25.6609L25.7469 12.7422V12.9777H25.802Z' fill='%23F79E1B'/%3E%3Cpath d='M26.0771 8.00046C26.077 9.50132 25.658 10.9719 24.8677 12.2441C24.0775 13.5163 22.948 14.5387 21.6084 15.1946C20.2688 15.8504 18.7731 16.1132 17.2922 15.9529C15.8114 15.7927 14.4052 15.2157 13.2344 14.2881C14.1794 13.5397 14.9434 12.5848 15.4688 11.4954C15.9941 10.406 16.2671 9.21057 16.2671 7.99935C16.2671 6.78813 15.9941 5.59273 15.4688 4.50334C14.9434 3.41395 14.1794 2.45901 13.2344 1.71059C14.4052 0.782978 15.8114 0.206049 17.2922 0.0457536C18.7731 -0.114542 20.2688 0.148262 21.6084 0.804127C22.948 1.45999 24.0775 2.48245 24.8677 3.75463C25.658 5.02681 26.077 6.49738 26.0771 7.99824V8.00046Z' fill='%23F79E1B'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath d='M0.447266 0H26.1315V16H0.447266V0Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
`
