import React, { FC } from 'react'
import styled, { keyframes } from 'styled-components'

export const Loader: FC = () => (
  <LoaderWrapper data-testid="loader">Loading...</LoaderWrapper>
)

const load = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const LoaderWrapper = styled.div`
  font-size: 10px;
  position: relative;
  margin: 0 auto;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #ffffff;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;

  &,
  &::after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`
