import React from 'react'
import Modal from 'styled-react-modal'

import { ICarInfo } from '@app/types'

import ModalContent from './components/ModalContent'
interface IProps {
  rulesModalIsOpen: boolean
  closeModal: () => void
  paymentValue: number | number[]
  carInfo: ICarInfo
}

const StyledModal = Modal.styled`
  overflow: scroll;
  height: 90%;

  @media (max-width: 1024px) {
    height: 100%;
  }
`

const RulesModal: React.FC<IProps> = ({
  closeModal,
  rulesModalIsOpen,
  carInfo,
  paymentValue,
}) => {
  return (
    <StyledModal
      isOpen={rulesModalIsOpen}
      onBackgroundClick={closeModal}
      onEscapeKeydown={closeModal}
    >
      <ModalContent
        closeModal={closeModal}
        carInfo={carInfo}
        paymentValue={paymentValue}
      />
    </StyledModal>
  )
}

export default RulesModal
