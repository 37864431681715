import { useKeycloak as keycloak } from 'use-keycloak'

export const useKeycloak = () => {
  return keycloak({
    realm: 'vw',
    url: process.env.REACT_APP_IDP_AUTH,
    clientId: 'audi',
    enableLogging: true,
    extraQueryParams: [['themeid', 'audi']],
    autoRefresh: true,
    autoRefreshInterval: 2500,
    onLoad: 'check-login',
    scope: 'profile email phone phone_required 2fa',
    redirectUri: `${window.location.origin}/auth`,
    ...(process.env.NODE_ENV === 'development' && {
      useDangerousCookies: true,
    }),
  })
}
