import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'

import { ICarInfo } from '@app/types'
import { sendEventToDataLayer } from '@app/utils/metrics/dataLayerEvent'
import dataLayerHandlers from '@app/utils/metrics/dataLayerHandlers'
import { CHECK_STATUS_INTERVAL } from '@app/views/PaymentPage/constants'

const useStatus = (
  carInfo: ICarInfo,
  statusCode: string,
  remainingTime: number
) => {
  const location = useLocation()

  const { amountPaid, modelName } = carInfo

  const [time, setTime] = useState(remainingTime)

  useEffect(() => {
    if (time > 0 && statusCode === 'reserve_not_payed') {
      const timer = setInterval(() => setTime(time - 1), 1000)
      return () => clearInterval(timer)
    }
  }, [time])

  useEffect(() => {
    if (
      statusCode === 'payment_wait' ||
      statusCode === 'rse_reserve_wait' ||
      statusCode === 'rse_reserve_wait_paid'
    ) {
      const checkStatus = setInterval(
        () => window.location.replace(location.pathname),
        CHECK_STATUS_INTERVAL
      )
      return () => clearInterval(checkStatus)
    }
  }, [statusCode])

  useEffect(() => {
    //   if (id && rseId) {
    //     sendRequestIdToDataLayer(id, rseId)
    //   }

    sendEventToDataLayer(
      'AVN_Booking',
      'debug_success',
      `isReserveSuccess=${Cookies.get(
        'isReserveSuccess'
      )}, statusCode=${statusCode}, amountPaid=${amountPaid}`
    )

    if (
      Cookies.get('isReserveSuccess') &&
      (statusCode === 'reserve_not_payed' ||
        statusCode === 'rse_reserve_wait' ||
        statusCode === 'payment_wait' ||
        statusCode === 'rse_reserve_wait_paid')
    ) {
      Cookies.remove('isReserveSuccess')

      dataLayerHandlers.booking.success(
        amountPaid !== 0 ? 'success_pay_online' : 'success_pay_at_dealer',
        modelName
      )
    }
    //   if (
    //     statusCode === 'payment_wait' ||
    //     statusCode === 'rse_reserve_wait_paid'
    //   ) {
    //     dataLayerHandlers.onlineBooking.successPrepaid(
    //       modelName,
    //       infoForDataLayer,
    //     )
    //   }
    //   if (
    //     statusCode === 'reserve_canceled' &&
    //     statusCode === 'rse_reserve_failed'
    //   ) {
    //     if (onlinePayment) {
    //       dataLayerHandlers.onlineBooking.sendStatusError(
    //         statusCode,
    //         infoForDataLayer,
    //       )
    //     } else {
    //       dataLayerHandlers.booking.sendStatusError(statusCode, infoForDataLayer)
    //     }
    //   } else if (onlinePayment) {
    //     dataLayerHandlers.onlineBooking.sendStatusSuccess(
    //       statusCode,
    //       infoForDataLayer,
    //     )
    //   } else {
    //     dataLayerHandlers.booking.sendStatusSuccess(statusCode, infoForDataLayer)
    //   }
  }, [])

  return {
    time,
  }
}

export default useStatus
