import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@app/hooks/useAuth'
import saveRedirectPage from '@app/utils/saveRedirectPage'

import { BookingButtonStyled } from '../../../styled'

interface IProps {
  isAgree: boolean
  setIsTried: (e: boolean | undefined) => void
  sendRequest: () => void
  newPayment: number | number[]
}

export const RetryBookingButton: FC<IProps> = ({
  isAgree,
  setIsTried,
  sendRequest,
  newPayment,
}) => {
  const location = useLocation()
  const { authToken, login } = useAuth()

  return (
    <BookingButtonStyled
      data-testid="booking-button"
      stretch
      onClick={() => {
        window.sessionStorage.setItem('kdx_retry_payment', `${newPayment}`)

        if (authToken && isAgree) {
          sendRequest()
        } else if (isAgree) {
          login()

          window.sessionStorage.setItem('can_send_request', 'true')

          saveRedirectPage(location.pathname)
        } else {
          setIsTried(true)
        }
      }}
    >
      {authToken
        ? 'Забронировать автомобиль'
        : 'Авторизоваться и забронировать'}
    </BookingButtonStyled>
  )
}
