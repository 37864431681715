import React from 'react'
import { Link } from 'react-router-dom'
import { Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

import { Loader } from '@app/components/Loader'
import useOrders from '@app/hooks/useOrders'
import moneyFormat from '@app/utils/moneyFormat'

export const OrdersPageContent = () => {
  const { isLoading, orderList } = useOrders()

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <OrderList>
          {orderList && orderList.length ? (
            orderList.map((item: any) => (
              <Link
                to={`/payment/${item.reserveId}/status`}
                key={item.reserveId}
              >
                <OrderItem>
                  <OrderImage src={item.image} alt={item.kitCode} />

                  <OrderInfo>
                    <Col>
                      <Text variant="order3">Audi {item.modelName}</Text>

                      <DealerInfo>
                        <Text variant="copy2">
                          {item.dealer.props.name}
                          <br />
                          {item.dealer.props.address}
                        </Text>
                      </DealerInfo>
                    </Col>

                    <div>
                      <Text variant="copy2">{item.reserveId}</Text>
                    </div>
                    <div>
                      <Text variant="copy2">{moneyFormat(item.price)}</Text>
                    </div>
                  </OrderInfo>
                </OrderItem>
              </Link>
            ))
          ) : (
            <div>
              <Text variant="copy2">В заказах пока ничего нет</Text>
            </div>
          )}
        </OrderList>
      )}
    </>
  )
}

const DealerInfo = styled.div``

const OrderItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 32px;
  background-color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 16px;
    flex-direction: column;
  }
`

const OrderInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`
const OrderImage = styled.img`
  width: 250px;
  margin-right: 24px;

  @media (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
`

const Col = styled.div`
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 16px;
  }
`

const OrderList = styled.div`
  width: 100%;

  a {
    text-decoration: none;
  }
`
