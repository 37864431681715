import React from 'react'
import { Text } from '@audi/audi-ui-react'
import styled from 'styled-components'

export const BookingWithoutPayment = () => {
  return (
    <Wrapper>
      <SectionTitle>Бронирование без предоплаты</SectionTitle>
      <Description>
        Резервирование автомобиля на 12 часов. Выбранный автомобиль может быть
        забронирован другим клиентом в дилерском центре. Для гарантирования
        наличия автомобиля и фиксации стоимости вы можете внести предоплату.
      </Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 64px;
  background: rgba(51, 51, 51, 0.2);

  @media (max-width: 1024px) {
    padding: 24px;
  }

  @media (max-width: 767px) {
    padding: 24px 12px;
    box-sizing: initial;
    margin-left: -12px;
    margin-right: -12px;
  }
`

const SectionTitle = styled(Text).attrs({ variant: 'order3' })`
  margin-bottom: 40px;
`

const Description = styled(Text).attrs({
  variant: 'copy1',
})`
  color: ${({ theme }) => theme.colors.text.inactive};
`
