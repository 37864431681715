import React, { FC, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

import { useAuth } from '@app/hooks/useAuth'
import usePaymentLink from '@app/hooks/usePaymentLink'
import useTimer from '@app/hooks/useTimer'
import getDataForRequest from '@app/utils/getDataForRequest'
import sendRequest from '@app/utils/sendRequest'

const withRequest = (
  WrappedComponent: FC<{
    time: string
    isLoading: boolean
    paymentLink: string
    dealerName: string
  }>
) => {
  return (props: any) => {
    const { carInfo, paymentType, paymentValue, setStep } = props
    const { authToken } = useAuth()

    const [isLoading, setLoading] = useState(false)

    const { paymentLink, setPaymentLink } = usePaymentLink(paymentValue)
    const { time } = useTimer(paymentLink)

    // const sendErrorEvent = () => {
    //   if (onlinePayment) {
    //     if (payment === 0) {
    //       dataLayerHandlers.onlineBooking.error(modelName);
    //     } else {
    //       dataLayerHandlers.onlineBooking.errorPrepaid(modelName);
    //     }
    //   } else {
    //     dataLayerHandlers.booking.error(modelName);
    //   }
    // };

    // const data = {
    //   vin,
    //   dealer: dealerCode || code,
    //   payment: paymentType === 'payment' ? paymentValue : 0,
    //   success_url: `${process.env.REACT_APP_APP_URL}/payment/${pageId}/success`,
    //   fail_url: `${process.env.REACT_APP_APP_URL}/payment/${pageId}/error`,
    //   price: currentPrice,
    //   rights_granted: true,
    //   agree_receive_advertising: true,
    //   lead_type: 'Импортер',
    //   analytics_user_id: Cookies.get('rst-uid') || null,
    // }

    const data = getDataForRequest(
      carInfo,
      paymentType === 'payment' ? paymentValue : 0
    )

    const hadleSendRequest = () => {
      setLoading(true)

      sendRequest(authToken, data)
        .then(({ data: { reserve, result } }) => {
          window.sessionStorage.removeItem('kdx_delivery')
          window.sessionStorage.removeItem('kdx_last_payment')
          window.sessionStorage.setItem('kdx_last_id', reserve.id)

          Cookies.set('isReserveSuccess', 'true')

          if (result && result.paymentUrl) {
            setPaymentLink(result.paymentUrl)
            setLoading(false)
          } else if (reserve.uuid) {
            window.location.replace(
              `${process.env.REACT_APP_APP_URL}/payment/${reserve.id}/status`
            )
          }
        })
        .catch((error) => {
          if (error.response) {
            // Request made and server responded
            const {
              data: {
                request_errors: requestErrors,
                system_errors: systemErrors,
              },
              status,
            } = error.response

            if (requestErrors && requestErrors.request_limit_exceeded) {
              setStep('limit')
              // if (onlinePayment) {
              //   dataLayerHandlers.onlineBooking.limitRequest(modelName);
              // } else {
              //   dataLayerHandlers.booking.limitRequest(modelName);
              // }
            } else if (requestErrors || systemErrors) {
              setStep('system-error')

              window.sessionStorage.removeItem('kdx_last_payment')
              console.log('systemErrors', systemErrors)
            }

            if (status === 403) {
              setStep('login')
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request)
            setStep('error')
            // sendErrorEvent();
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
            setStep('error')
            // sendErrorEvent();
          }
          setLoading(false)
        })
    }

    useEffect(() => {
      hadleSendRequest()
    }, [])

    return (
      <WrappedComponent
        {...props}
        time={time}
        isLoading={isLoading}
        paymentLink={paymentLink}
        dealerName={carInfo.dealer.dealerName}
      />
    )
  }
}

export default withRequest
