import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from '@audi/audi-ui-react'
import styled from 'styled-components'

import { useAuth } from '@app/hooks/useAuth'
import useOrders from '@app/hooks/useOrders'
import saveRedirectPage from '@app/utils/saveRedirectPage'

import { Orders } from './Orders'
import { LogoIcon } from '../icons'

const Container = styled.div`
  position: relative;
  padding: 24px 12px;
  margin-bottom: 64px;

  @media (max-width: 767px) {
    padding: 24px 0;
  }
`

export const Header = () => {
  const { isLoading, orderList } = useOrders()
  const { authToken, login } = useAuth()
  const location = useLocation()

  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => {
    if (!authToken) {
      login()
      saveRedirectPage(location.pathname)
    } else {
      setIsOpen(!isOpen)
    }
  }

  const closeMenu = useCallback(() => setIsOpen(false), [])

  return (
    <Container>
      <Layout justify="between">
        <LogoIcon />
        <Orders
          isOpen={isOpen}
          orderList={orderList}
          isLoading={isLoading}
          openMenu={openMenu}
          closeMenu={closeMenu}
        />
      </Layout>
    </Container>
  )
}
