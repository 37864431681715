import declension from '@app/utils/declension'

export default (timeInSeconds: number) => {
  if (!timeInSeconds) return '0 минут'

  const hours = timeInSeconds / 60 / 60
  const rhours = Math.floor(hours)
  const minutes = (hours - rhours) * 60
  const rminutes = Math.round(minutes)
  const strHours = declension(rhours, ['час', 'часа', 'часов'])
  const strMinutes = declension(rminutes, ['минута', 'минуты', 'минут'])

  return `${rhours} ${strHours} ${rminutes} ${strMinutes}`
}
