declare global {
  interface Window {
    dataLayer_kdx: any
  }
}

export const sendEventToDataLayer = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string | number | number[] | undefined = undefined,
  additionalInfo?: any
) => {
  window.dataLayer_kdx = window.dataLayer_kdx || []
  window.dataLayer_kdx.push({
    event: 'send_ga',
    eventCategory,
    eventAction,
    eventLabel,
    ...additionalInfo,
  })
}
