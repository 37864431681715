import React, { FC } from 'react'

// import dataLayerHandlers from 'Metrics/dataLayerHandlers'
import { Loader } from '@app/components/Loader'
import { PaymentMethodType } from '@app/views/PaymentPage/types'

import { StepType } from './../../types'
import withRequest from './withRequest'
import {
  LoaderWrapper,
  PreparingDescription,
  PreparingMessage,
  PreparingTitle,
} from '../styled'

interface IProps {
  setStep: (e: StepType) => void
  carInfo: any
  paymentValue: number | number[]
  paymentType: PaymentMethodType
}

export const Confirmation: FC<IProps> = withRequest(
  ({ time, isLoading, paymentLink, dealerName }) => {
    return isLoading ? (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    ) : (
      <>
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>

        <PreparingTitle>Подготовка к предоплате</PreparingTitle>

        <PreparingMessage>
          Через {time} секунд вы будете перенаправлены на&nbsp;страницу оплаты.
          Если это не происходит автоматически,{' '}
          <a href={paymentLink}>нажмите&nbsp;сюда</a>
        </PreparingMessage>

        <PreparingDescription>
          Наличие автомобиля и его конечная стоимость гарантированы после
          получения предоплаты. В&nbsp;ближайшее время с&nbsp;вами свяжется
          представитель официального дилера {dealerName}.
        </PreparingDescription>
      </>
    )
  }
)
