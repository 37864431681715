import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'

import { IOrder } from '@app/types/order.types'

import { useAuth } from './useAuth'

interface OrdersResponse {
  requests: {
    id: string
    props: IOrder
  }[]
}

const useOrders = () => {
  const [orderList, setOrderList] = useState<IOrder[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const { authToken } = useAuth()

  const getOrders = useCallback(() => {
    setIsLoading(true)

    axios
      .get<OrdersResponse>(`${process.env.REACT_APP_PERSONAL_URL}`, {
        method: 'get',
        timeout: 30000,
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      })
      .then(({ data }) => {
        const resultList = data.requests.map((item) => item.props)

        setOrderList(resultList)
        setIsLoading(false)
      })
  }, [authToken])

  useEffect(() => {
    if (authToken) {
      getOrders()
    }
  }, [authToken])

  return {
    orderList,
    isLoading,
  }
}

export default useOrders
