import React, { FC, useCallback, useState } from 'react'

import { ICarInfo } from '@app/types'
import { PaymentMethodType } from '@app/views/PaymentPage/types'

import { StepType } from './../../types'
import { BookingWithoutPayment } from './components/BookingWithoutPayment'
import {
  AutorizationInfo,
  BookingButton,
  BookingMethod,
  CheckboxAgree,
  PaymentAmount,
  RulesModal,
} from './components'
import { DEFAULT_MAX_PAYMENT_VALUE } from '../../constants'

interface IProps {
  setStep: (e: StepType) => void
  carInfo: ICarInfo
  paymentType: PaymentMethodType
  setPaymentType: (event: PaymentMethodType) => void
  paymentValue: number | number[]
  setPaymentValue: (e: number | number[]) => void
}

export const Reservation: FC<IProps> = ({
  carInfo,
  setStep,
  paymentType,
  setPaymentType,
  paymentValue,
  setPaymentValue,
}) => {
  const [rulesModalIsOpen, setRulesModalIsOpen] = useState<boolean>(false)
  const [isTried, setIsTried] = useState<boolean | undefined>(undefined)
  const [isAgree, setIsAgree] = useState<boolean>(false)

  const closeModal = useCallback(() => setRulesModalIsOpen(false), [])

  const carPrice = carInfo?.price || carInfo?.finalPrice
  const maxPayment =
    !!carPrice && carInfo.dealer.maxPayment >= carPrice
      ? carPrice
      : carInfo.dealer.maxPayment

  return (
    <>
      <AutorizationInfo />

      {carInfo.dealer.onlinePayment ? (
        <BookingMethod
          carInfo={carInfo}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
          paymentValue={paymentValue}
          setPaymentValue={setPaymentValue}
        />
      ) : (
        <BookingWithoutPayment />
      )}

      {paymentType === 'payment' && (
        <PaymentAmount
          minPayment={carInfo?.dealer?.minPayment || 0}
          maxPayment={maxPayment || DEFAULT_MAX_PAYMENT_VALUE}
          paymentValue={paymentValue}
          setPaymentValue={setPaymentValue}
        />
      )}

      <CheckboxAgree
        paymentType={paymentType}
        setIsAgree={setIsAgree}
        carInfo={carInfo}
        setRulesModalIsOpen={setRulesModalIsOpen}
        setIsTried={setIsTried}
        isTried={isTried}
      />

      <BookingButton
        setStep={setStep}
        paymentType={paymentType}
        paymentValue={paymentValue}
        carInfo={carInfo}
        isAgree={isAgree}
        setIsTried={setIsTried}
      />

      <RulesModal
        rulesModalIsOpen={rulesModalIsOpen}
        closeModal={closeModal}
        carInfo={carInfo}
        paymentValue={paymentValue}
      />
    </>
  )
}
