import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import Auth from './components/Auth'
import { useAuth } from './hooks/useAuth'
import saveRedirectPage from './utils/saveRedirectPage'
import { OrdersPage } from './views/OrdersPage'
import { PaymentPage } from './views/PaymentPage'

export const Routes = () => {
  const { authToken } = useAuth()

  const { pathname } = useLocation()

  if (!authToken && pathname.match('/status')) {
    saveRedirectPage(pathname)
    // login()
  }

  return (
    <div>
      <Switch>
        <Route exact path="/" component={PaymentPage} />
        <Route exact path="/orders" component={OrdersPage} />
        <Route exact path="/auth" component={Auth} />

        <Route exact path="/payment/:id" component={PaymentPage} />
        <Route exact path="/payment/:id/:result" component={PaymentPage} />
      </Switch>
    </div>
  )
}
