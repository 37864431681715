import React, { FC } from 'react'

import { Loader } from '@app/components/Loader'
import useStatus from '@app/hooks/useStatus'
import { ICarInfo } from '@app/types'
import calculateRemainingTime from '@app/utils/calculateRemainingTime'

import Result from './Result'
import { BankDisclaimer } from '../../BankDisclaimer'
import { StepType } from '../../types'
import Retry from '../Retry'
import { Container } from '../styled'

const ONE_DAY_IN_SECONDS = 43170 // 12 hours

interface IProps {
  setStep: (e: StepType) => void
  carInfo: ICarInfo
  uuid?: string | string[] | undefined
}

export const Status: FC<IProps> = ({ carInfo, setStep, uuid }) => {
  const {
    amountPaid,
    confirmedPaid,
    reserveId,
    // rse_id: rseId,
    createdAt,
    statusCode,
    onlinePayment,
  } = carInfo
  // const parsedToken = token ? jwtDecode(token) : null
  // const infoForDataLayer = parsedToken
  //   ? {
  //       eventPhone:
  //         parsedToken.phone_number &&
  //         parsedToken.phone_number.replace(/[^\d]/g, ''),
  //       eventEmail: parsedToken.email,
  //     }
  //   : {}
  const payment = amountPaid + confirmedPaid
  const requestDate = new Date(createdAt).getTime() / 1000 // request date in seconds
  const isExistData = payment >= 0 && !!reserveId && !!createdAt

  const remainingTime = calculateRemainingTime(isExistData, requestDate)

  const dateOfExpiry = new Date((requestDate + ONE_DAY_IN_SECONDS) * 1000)
    .toLocaleDateString('en-GB')
    .replace(/\//g, '.')

  const { time } = useStatus(carInfo, statusCode, remainingTime)

  return isExistData ? (
    <>
      <Container>
        <Result
          statusCode={statusCode}
          time={time}
          dealerInfo={carInfo.dealer}
          payment={payment}
          id={reserveId}
          dateOfExpiry={dateOfExpiry}
        />
      </Container>

      {onlinePayment &&
        statusCode === 'reserve_not_payed' &&
        remainingTime > 0 && (
          <>
            <Retry
              data-testid="retry"
              payment={payment}
              setStep={setStep}
              carInfo={carInfo}
              isStatusPage
              uuid={reserveId || uuid}
            />
            <BankDisclaimer />
          </>
        )}
    </>
  ) : (
    <Loader />
  )
}
