import * as React from 'react'

const SvgArrowDown = React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1L6.5 6.5L12 1" stroke="white" />
  </svg>
))
export default SvgArrowDown
