import React from 'react'
import { Button, Layout, LayoutItem, Text } from '@audi/audi-ui-react'

import { Header } from '@app/views/PaymentPage/Displays'

import { Container } from '../styled'

export const Error = () => (
  <Container>
    <Header />

    <Layout justify="center">
      <LayoutItem basis={{ xs: 'auto', l: '760px' }}>
        <Layout justify="center" direction="column">
          <Text variant="order2" weight="bold" spaceStackEnd="s">
            Мы приносим свои извинения, но по данному адресу ничего не найдено.
          </Text>
          <Text spaceStackEnd="xxl">
            Причины, которые могли привести к такой ошибке:
            <br />— неправильно набран адрес;
            <br />— такой страницы никогда не было на этом сайте;
            <br />— страница была удалена.
          </Text>
        </Layout>
        <Button
          variant="secondary"
          href="https://www.audi.ru/ru/web/ru/car-stock.html"
        >
          Вернуться в каталог
        </Button>
      </LayoutItem>
    </Layout>
  </Container>
)
