import * as React from 'react'
const SvgMkb = React.memo((props: React.SVGProps<SVGSVGElement>) => (
  <svg width={91} height={16} fill="none" viewBox="0 0 91 16" {...props}>
    <path
      fill="#DD0A34"
      d="M44.645 0h-3.833v16h3.413V5.445h.21l3.622 6.09h1.824l3.613-6.09h.21V16h3.443V0h-3.77l-4.34 7.26L44.646 0zM64.292 0h-3.46v16h3.457V9.17h1.73L70.79 16H75.3l-6.382-8.526L74.739 0h-4.324L65.99 5.898l-1.706.192.007-6.09z"
    />
    <path
      fill="#DD0A34"
      fillRule="evenodd"
      d="M77.809 0h12.439l-3.064 3.224h-5.892v2.483h3.585a8.868 8.868 0 012.453.31 5.62 5.62 0 011.963.955c.524.429.954.966 1.264 1.576.299.716.45 1.49.443 2.269a5.614 5.614 0 01-.443 2.22 5.37 5.37 0 01-1.285 1.625 6.011 6.011 0 01-1.94 1.003c-.799.226-1.624.339-2.453.335h-7.07V0zm3.483 8.836V12.8l3.597-.002c.865 0 1.517-.192 1.94-.527.21-.173.38-.395.493-.647.114-.253.169-.529.162-.807a1.716 1.716 0 00-.148-.813 1.668 1.668 0 00-.508-.642c-.43-.358-1.05-.526-1.845-.526h-3.69z"
      clipRule="evenodd"
    />
    <path
      fill="#DD0A34"
      d="M19.342.017L11.579 8l-3.88-3.993L11.585 0h-7.77L0 4.007 11.661 16l9.955-10.322h5.958L33.077.017H19.342z"
    />
    <path
      fill="#DD0A34"
      d="M13.26 15.828l1.116-1.135 7.673-7.905h11.032l-5.507 5.658h-3.236l-3.276 3.382H13.26z"
    />
  </svg>
))
export default SvgMkb
