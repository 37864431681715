const logisticStatuses = {
  7: {
    color: 'grey',
    headline: 'Склад Ауди в Европе',
    text: 'Автомобиль на складе Ауди в Европе',
  },
  8: {
    color: 'grey',
    headline: 'Доставка в Россию',
    text: 'Автомобиль находится на пути в Россию',
  },
  9: {
    color: 'yellow',
    headline: 'Склад Ауди в России',
    text: 'Автомобиль прошел таможню и находится на складе Ауди в России',
  },
  10: {
    color: 'yellow',
    headline: 'Доставка дилеру',
    text: 'Автомобиль на пути в дилерский центр',
  },
  11: {
    color: 'green',
    headline: 'В наличии на складе дилера',
    text: 'Автомобиль на складе дилера.',
  },
  12: {
    color: 'green',
    headline: 'В наличии на складе дилера',
    text: 'Автомобиль на складе дилера.',
  },
}

type Status = keyof typeof logisticStatuses

export const getLogisticStatus = (status: Status) =>
  logisticStatuses[status] || null
