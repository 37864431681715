const moneyFormat = (number: number | undefined, maxDigits = 0) => {
  if (typeof number !== 'number') return null

  const options = {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  }
  const moneyFormat = new Intl.NumberFormat('ru-RU', options)
  const formatted = moneyFormat.format(number)

  return formatted
}

export default moneyFormat
